a{
    color: $link;
    text-decoration: none;
    font-variation-settings: $bold;
    @include transition(color $speed ease-in);
    &:hover {
        color: $hover;
        text-decoration: none;
    }

    &.button{
        text-transform: uppercase;
        @include font-size(1.4);
        letter-spacing: 0.7px;
    }

    &.link-black{
        color: $link-black;
        @include transition(color $speed ease-in);
        &:hover {
            color: $hover-white;
        }
    }

    &.link-white{
        color: $magenta-light;
        @include transition(color $speed ease-in);
        &:hover {
            color: $hover-black;
        }

    }
}

button {
    border: none;
    outline: none;
    padding: 0;
    background: none;
    cursor: pointer;
}
.button-block {
    overflow: hidden;
    margin: 0 auto;
    @include min(sm) {
        margin-bottom: $gap-sm;
        margin-top: $gap-sm;
    }
    @include min(lg) {
        margin-bottom: $gap-sm;
        margin-top: $gap-sm;
    }
    & a {
        text-decoration: none;
        text-transform: uppercase;
        color: $white;
        background-color: $text-color;
        @include transition(background $speed ease-in);
        letter-spacing: 0.8px;
        display: inline-block;
        padding: 4px 30px;
        //margin-top: 20px;
        @include min(sm) {
        	padding: 8px 40px;
        }
        &:hover {
            background: $magenta-light;
        }
    }
    &.last{
        margin-top: 37px;
        margin-bottom: 37px;
    }
}


.bigButton{
    width: 100%;
    a{
        width: 100%;
        color: $white;
        background: $magenta-light;
        text-align: left;
        padding: 30px 60px;
        letter-spacing: 1.6px;
        &:hover {
            background: $grey;
        }
    }
}



















