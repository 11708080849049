$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-activity: "\e900";
$icon-airplay: "\e901";
$icon-alert-circle: "\e902";
$icon-alert-octagon: "\e903";
$icon-alert-triangle: "\e904";
$icon-align-center: "\e905";
$icon-align-justify: "\e906";
$icon-align-left: "\e907";
$icon-align-right: "\e908";
$icon-anchor: "\e909";
$icon-aperture: "\e90a";
$icon-archive: "\e90b";
$icon-arrow-down: "\e90c";
$icon-arrow-down-circle: "\e90d";
$icon-arrow-down-left: "\e90e";
$icon-arrow-down-right: "\e90f";
$icon-arrow-left: "\e910";
$icon-arrow-left-circle: "\e911";
$icon-arrow-right: "\e912";
$icon-arrow-right-circle: "\e913";
$icon-arrow-up: "\e914";
$icon-arrow-up-circle: "\e915";
$icon-arrow-up-left: "\e916";
$icon-arrow-up-right: "\e917";
$icon-at-sign: "\e918";
$icon-award: "\e919";
$icon-bar-chart: "\e91a";
$icon-bar-chart-2: "\e91b";
$icon-battery: "\e91c";
$icon-battery-charging: "\e91d";
$icon-bell: "\e91e";
$icon-bell-off: "\e91f";
$icon-bluetooth: "\e920";
$icon-bold: "\e921";
$icon-book: "\e922";
$icon-book-open: "\e923";
$icon-bookmark: "\e924";
$icon-box: "\e925";
$icon-briefcase: "\e926";
$icon-calendar: "\e927";
$icon-camera: "\e928";
$icon-camera-off: "\e929";
$icon-cast: "\e92a";
$icon-check: "\e92b";
$icon-check-circle: "\e92c";
$icon-check-square: "\e92d";
$icon-chevron-down: "\e92e";
$icon-chevron-left: "\e92f";
$icon-chevron-right: "\e930";
$icon-chevron-up: "\e931";
$icon-chevrons-down: "\e932";
$icon-chevrons-left: "\e933";
$icon-chevrons-right: "\e934";
$icon-chevrons-up: "\e935";
$icon-chrome: "\e936";
$icon-circle: "\e937";
$icon-clipboard: "\e938";
$icon-clock: "\e939";
$icon-cloud: "\e93a";
$icon-cloud-drizzle: "\e93b";
$icon-cloud-lightning: "\e93c";
$icon-cloud-off: "\e93d";
$icon-cloud-rain: "\e93e";
$icon-cloud-snow: "\e93f";
$icon-code: "\e940";
$icon-codepen: "\e941";
$icon-codesandbox: "\e942";
$icon-coffee: "\e943";
$icon-columns: "\e944";
$icon-command: "\e945";
$icon-compass: "\e946";
$icon-copy: "\e947";
$icon-corner-down-left: "\e948";
$icon-corner-down-right: "\e949";
$icon-corner-left-down: "\e94a";
$icon-corner-left-up: "\e94b";
$icon-corner-right-down: "\e94c";
$icon-corner-right-up: "\e94d";
$icon-corner-up-left: "\e94e";
$icon-corner-up-right: "\e94f";
$icon-cpu: "\e950";
$icon-credit-card: "\e951";
$icon-crop: "\e952";
$icon-crosshair: "\e953";
$icon-database: "\e954";
$icon-delete: "\e955";
$icon-disc: "\e956";
$icon-dollar-sign: "\e957";
$icon-download: "\e958";
$icon-download-cloud: "\e959";
$icon-droplet: "\e95a";
$icon-edit: "\e95b";
$icon-edit-2: "\e95c";
$icon-edit-3: "\e95d";
$icon-external-link: "\e95e";
$icon-eye: "\e95f";
$icon-eye-off: "\e960";
$icon-facebook: "\e961";
$icon-fast-forward: "\e962";
$icon-feather: "\e963";
$icon-figma: "\e964";
$icon-file: "\e965";
$icon-file-minus: "\e966";
$icon-file-plus: "\e967";
$icon-file-text: "\e968";
$icon-film: "\e969";
$icon-filter: "\e96a";
$icon-flag: "\e96b";
$icon-folder: "\e96c";
$icon-folder-minus: "\e96d";
$icon-folder-plus: "\e96e";
$icon-framer: "\e96f";
$icon-frown: "\e970";
$icon-gift: "\e971";
$icon-git-branch: "\e972";
$icon-git-commit: "\e973";
$icon-git-merge: "\e974";
$icon-git-pull-request: "\e975";
$icon-github: "\e976";
$icon-gitlab: "\e977";
$icon-globe: "\e978";
$icon-grid: "\e979";
$icon-hard-drive: "\e97a";
$icon-hash: "\e97b";
$icon-headphones: "\e97c";
$icon-heart: "\e97d";
$icon-help-circle: "\e97e";
$icon-hexagon: "\e97f";
$icon-home: "\e980";
$icon-image: "\e981";
$icon-inbox: "\e982";
$icon-info: "\e983";
$icon-instagram: "\e984";
$icon-italic: "\e985";
$icon-key: "\e986";
$icon-layers: "\e987";
$icon-layout: "\e988";
$icon-life-buoy: "\e989";
$icon-link: "\e98a";
$icon-link-2: "\e98b";
$icon-linkedin: "\e98c";
$icon-list: "\e98d";
$icon-loader: "\e98e";
$icon-lock: "\e98f";
$icon-log-in: "\e990";
$icon-log-out: "\e991";
$icon-mail: "\e992";
$icon-map: "\e993";
$icon-map-pin: "\e994";
$icon-maximize: "\e995";
$icon-maximize-2: "\e996";
$icon-meh: "\e997";
$icon-menu: "\e998";
$icon-message-circle: "\e999";
$icon-message-square: "\e99a";
$icon-mic: "\e99b";
$icon-mic-off: "\e99c";
$icon-minimize: "\e99d";
$icon-minimize-2: "\e99e";
$icon-minus: "\e99f";
$icon-minus-circle: "\e9a0";
$icon-minus-square: "\e9a1";
$icon-monitor: "\e9a2";
$icon-moon: "\e9a3";
$icon-more-horizontal: "\e9a4";
$icon-more-vertical: "\e9a5";
$icon-mouse-pointer: "\e9a6";
$icon-move: "\e9a7";
$icon-music: "\e9a8";
$icon-navigation: "\e9a9";
$icon-navigation-2: "\e9aa";
$icon-octagon: "\e9ab";
$icon-package: "\e9ac";
$icon-paperclip: "\e9ad";
$icon-pause: "\e9ae";
$icon-pause-circle: "\e9af";
$icon-pen-tool: "\e9b0";
$icon-percent: "\e9b1";
$icon-phone: "\e9b2";
$icon-phone-call: "\e9b3";
$icon-phone-forwarded: "\e9b4";
$icon-phone-incoming: "\e9b5";
$icon-phone-missed: "\e9b6";
$icon-phone-off: "\e9b7";
$icon-phone-outgoing: "\e9b8";
$icon-pie-chart: "\e9b9";
$icon-play: "\e9ba";
$icon-play-circle: "\e9bb";
$icon-plus: "\e9bc";
$icon-plus-circle: "\e9bd";
$icon-plus-square: "\e9be";
$icon-pocket: "\e9bf";
$icon-power: "\e9c0";
$icon-printer: "\e9c1";
$icon-radio: "\e9c2";
$icon-refresh-ccw: "\e9c3";
$icon-refresh-cw: "\e9c4";
$icon-repeat: "\e9c5";
$icon-rewind: "\e9c6";
$icon-rotate-ccw: "\e9c7";
$icon-rotate-cw: "\e9c8";
$icon-rss: "\e9c9";
$icon-save: "\e9ca";
$icon-scissors: "\e9cb";
$icon-search: "\e9cc";
$icon-send: "\e9cd";
$icon-server: "\e9ce";
$icon-settings: "\e9cf";
$icon-share: "\e9d0";
$icon-share-2: "\e9d1";
$icon-shield: "\e9d2";
$icon-shield-off: "\e9d3";
$icon-shopping-bag: "\e9d4";
$icon-shopping-cart: "\e9d5";
$icon-shuffle: "\e9d6";
$icon-sidebar: "\e9d7";
$icon-skip-back: "\e9d8";
$icon-skip-forward: "\e9d9";
$icon-slack: "\e9da";
$icon-slash: "\e9db";
$icon-sliders: "\e9dc";
$icon-smartphone: "\e9dd";
$icon-smile: "\e9de";
$icon-speaker: "\e9df";
$icon-square: "\e9e0";
$icon-star: "\e9e1";
$icon-stop-circle: "\e9e2";
$icon-sun: "\e9e3";
$icon-sunrise: "\e9e4";
$icon-sunset: "\e9e5";
$icon-tablet: "\e9e6";
$icon-tag: "\e9e7";
$icon-target: "\e9e8";
$icon-terminal: "\e9e9";
$icon-thermometer: "\e9ea";
$icon-thumbs-down: "\e9eb";
$icon-thumbs-up: "\e9ec";
$icon-toggle-left: "\e9ed";
$icon-toggle-right: "\e9ee";
$icon-tool: "\e9ef";
$icon-trash: "\e9f0";
$icon-trash-2: "\e9f1";
$icon-trello: "\e9f2";
$icon-trending-down: "\e9f3";
$icon-trending-up: "\e9f4";
$icon-triangle: "\e9f5";
$icon-truck: "\e9f6";
$icon-tv: "\e9f7";
$icon-twitch: "\e9f8";
$icon-twitter: "\e9f9";
$icon-type: "\e9fa";
$icon-umbrella: "\e9fb";
$icon-underline: "\e9fc";
$icon-unlock: "\e9fd";
$icon-upload: "\e9fe";
$icon-upload-cloud: "\e9ff";
$icon-user: "\ea00";
$icon-user-check: "\ea01";
$icon-user-minus: "\ea02";
$icon-user-plus: "\ea03";
$icon-user-x: "\ea04";
$icon-users: "\ea05";
$icon-video: "\ea06";
$icon-video-off: "\ea07";
$icon-voicemail: "\ea08";
$icon-volume: "\ea09";
$icon-volume-1: "\ea0a";
$icon-volume-2: "\ea0b";
$icon-volume-x: "\ea0c";
$icon-watch: "\ea0d";
$icon-wifi: "\ea0e";
$icon-wifi-off: "\ea0f";
$icon-wind: "\ea10";
$icon-x: "\ea11";
$icon-x-circle: "\ea12";
$icon-x-octagon: "\ea13";
$icon-x-square: "\ea14";
$icon-youtube: "\ea15";
$icon-zap: "\ea16";
$icon-zap-off: "\ea17";
$icon-zoom-in: "\ea18";
$icon-zoom-out: "\ea19";

