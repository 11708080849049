.ce_form{
	.widget{
		input:not(.checkbox), select, textarea{
			width: 100%;
		    -webkit-box-sizing: border-box;
		    -moz-box-sizing: border-box;
		    box-sizing: border-box;
		   	font-family: 'Heebo';
		    font-variation-settings: $regular;
		    color: $black;
		    letter-spacing: 2px;
		    @include font-size(1.4);
		    margin-bottom: 20px;
		    border: none;
		    border-bottom: 2px solid $grey;
		    padding: 10px 10px;
		    padding-left: 0px;
	    	background-color: transparent;
		    @include transition(all, $speed ease-in);
		    &::placeholder{
		    	color: $grey-placeholder;
		    	text-transform: uppercase;
		    }
		}
		fieldset{
		   	font-family: 'Heebo';
		    @include font-size(1.4);
		    border: none;
		    padding: 10px 0px;
		    @include min(md) {
			    label{
			    		padding-left: 20px;
			    }
			    input{
			    	 transform: scale(1.2);
			    }
			    span{
			    	display: flex;
			    }
		    }

		}
		input:not(.checkbox), select, textarea{
			&:focus{
				outline: none;
		    	border-bottom: 2px solid $magenta-light;
			}
		}
	}
	/* hover animation */
	.widget-text, .widget-select{
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: $magenta-light;
            position: relative;
            top: -22px;
            left: 0;
            @include transition(width $speed ease-in);
		}
	    &:hover{
    		&:after {
	    		width: 100%;
	    	}
	    }
	}
	/* hover animation TEXTAREA*/
	.widget-textarea{
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: $magenta-light;
            position: relative;
            top: -29px;
            left: 0;
            @include transition(width $speed ease-in);
		}
	    &:hover{
    		&:after {
	    		width: 100%;
	    	}
	    }
	}
	/* checkbox animation */
	.widget-select{
		select{
			color: $grey-placeholder;
		    -moz-appearance:none; /* Firefox */
		    -webkit-appearance:none; /* Safari and Chrome */
		    appearance:none;
		    background: url(../../../files/images/icons/chevron-down.svg) no-repeat right;
		    background-position-x: 100%;
		    &:hover{
		    	background: url(../../../files/images/icons/chevron-down-magenta.svg) no-repeat right;
		    }
		}

	}
	/* REMOVE AUTOFILL BLUE BACKGROUND */
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus, 
	input:-webkit-autofill:active  {
	    -webkit-box-shadow: 0 0 0 30px white inset !important;
	}


	/* SUBMIT Absende-Button */
	.widget-submit{
		button.submit{
			background: none;
			border: 2px solid $grey;
			color: $magenta-light;
			letter-spacing: 2.8px;
			padding: 8px 40px;
			text-align: center;
			width: 100%;
			text-transform: uppercase;
		    @include transition(color, border, $speed ease-in);
			@include min(md) {
		    	margin-top: 50px;
				padding: 8px 120px;
			}
		    &:hover{
				border: 2px solid $magenta-light;
				color: $black;
		    }
		}
	}


}





/* FIX FIREFOX red borders */

body.firefox{
	.contact-form {
		.widget-textarea{
	        &:after {
	            top: -22px;
			}
		}
		input:required, textarea:required {
		    box-shadow:none;
		}


	}

}








