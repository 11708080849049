/* -----------------------------------
BOX MODEL
----------------------------------- */
*, *:before, *:after {
    @include box-sizing(border-box);
}

/* -----------------------------------
BASE
----------------------------------- */
html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}
body {
	width: 100%;
    &.overflow {
        //overflow: hidden;
        //position: fixed;
        .aside-buttons {
            @include transform(100%, -50%);
        }
    }
}
#main{
    margin-top: 50px;
}
.wow {
    @include min(lg) {
        visibility: hidden;
    }
}


.image_container {
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    & > a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }
}
        
.image-holder {
    position: relative;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

img {
    max-width: 100%;
    //width: 100%;
    height: auto;
}

ul {
    list-style-type: none;
    margin-bottom: 0;
    & li {
        position: relative;
    }
}

.container-custom{
    margin: 0 auto;
    & li:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 7px;
        left: -15px;
        background: $grey;
    }
}

.container-custom{
  width: 100%;
}
.magenta-bg{
    @include min(sm) {
        background: $magenta-light;
    }
}
.brown-bg{
    background: $magenta-dark;
    @include min(sm) {
        background: $magenta-dark;
    }
}
.grey-bg{
    //background: $grey;
    @include min(sm) {
        background: $grey;
    }
}


.padding-bottom-80{
    padding-bottom: 40px;
    @include min(sm) {
        padding-bottom: 80px;
    }
}



.container-D-Padding{
      padding-right: 20px;
      padding-left: 20px;
    @include min(sm) {
      padding-right: 20px;
      padding-left: 20px;
    }
    @include min(md) {
      padding-right: 30px;
      padding-left: 30px;
    }
}




strong{
    font-weight: 500;
    font-variation-settings: $bold;
}

table {
    width: 100%;
}
