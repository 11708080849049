/* VIDEO 
.ce_player {
	.mejs__container {
	    width: 100% !important;
	    height: auto !important;
	    margin: 0 auto;
	}
	.mejs__inner {
		padding-bottom: 56.25%;
	}
	.mejs__mediaelement {}
	video {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    margin: auto;
        object-fit: cover;
	}
	.mejs__overlay {
	    width: 100% !important;
	    height: 100% !important;
	}
	.mejs__poster {
		width: 100% !important;
		height: 100% !important;
		background-size: cover !important;
	}
	.mejs__overlay-button {
        background: none !important;
        &:before {
            @include iconmoon;
            // content: $icon-play;
            @include font-size(6.8);
            color: #fff;
        }
        &:hover {
            &:before {
            }
        }
    }
}
*/
/*  */
.ce_player{
	float: left;
	&.ce_player_in_grid{
		.container-custom{
			//@include padding;
			@include min(sm) {
				@include padding;
			}

			padding: 0;
			.row{
				@media (max-width: 767px) {
					margin: 0;
				}
				.teaser-block {
					width: 100%;
					padding: 0;
					@include min(sm) {
						@include padding;
					}
					.image-block{
						video {
							width: 100%;
							display: flex;
						}
					}
				}
			}
		}
	}
	&.ce_player_full_width{
	}
}
