@mixin box-sizing($box-model) {
	-webkit-box-sizing: $box-model;
	-moz-box-sizing: $box-model;
	box-sizing: $box-model;
}

@mixin fontfix {
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke: 1px transparent;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

@mixin font-size($sizeValue: 1.6) {
	font-size: ($sizeValue * 10) + px;
	font-size: $sizeValue + rem;
}

@mixin line-height($heightValue: 12 ){
	line-height: $heightValue + px;
	line-height: (0.125 * $heightValue) + rem;
}

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin transform($args...) {
	-webkit-transform: translate($args);
	-moz-transform: translate($args);
	-ms-transform: translate($args);
	-o-transform: translate($args);
	transform: translate($args);
}

@mixin transformX($args...) {
	-webkit-transform: translateX($args);
	-moz-transform: translateX($args);
	-ms-transform: translateX($args);
	-o-transform: translateX($args);
	transform: translateX($args);
}

@mixin transformY($args...) {
	-webkit-transform: translateY($args);
	-moz-transform: translateY($args);
	-ms-transform: translateY($args);
	-o-transform: translateY($args);
	transform: translateY($args);
}

@mixin rotate ($deg) {
	-webkit-transform: rotate(#{$deg}deg);
	-moz-transform: rotate(#{$deg}deg);
	-ms-transform: rotate(#{$deg}deg);
	transform: rotate(#{$deg}deg);
}

@mixin scale ($args...) {
	-webkit-transform: scale($args);
	-moz-transform: scale($args);
	-ms-transform: scale($args);
	transform: scale($args);
}

@mixin max($point) {
	@media (max-width: $point) { @content; }
}

@mixin min($point) {
    @if $point == xs {
        @media (min-width: 375px) { @content; }
    }
    @else if $point == sm {
        @media (min-width: 768px) { @content; }
    }
    @else if $point == md {
        @media (min-width: 992px) { @content; }
    }
    @else if $point == lg {
        @media (min-width: 1200px) { @content; }
    }
    @else if $point == xl {
        @media (min-width: 1400px)  { @content; }
    }
    @else if $point == xxl {
        @media (min-width: 1600px)  { @content; }
    }
	@else if $point == max {
		@media (min-width: 1920px)  { @content; }
	}
}

@mixin media-min($point) {
    @media (min-width: $point) { @content; }
}

@mixin clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin arrow($direction, $size, $color) {
	width: 0;
	height: 0;
	@if $direction == up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
	}
	@else if $direction == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
	}
	@else if $direction == right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
	}
	@else if $direction == left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right:$size solid $color;
	}
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content, > img, > a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin li-dot($width,$height,$bg) {
	content: '';
	display: block;
	width: $width;
	height: $height;
	background: $bg;
	border-radius: 50%;
}

@mixin iconmoon {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin webkitreset {
    -webkit-appearance: none;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
}

/* FLEX MTFKR */
@mixin flexbox {
  -js-display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($grow, $shrink, $basis) {
  -webkit-box-flex: $grow $shrink $basis;
  -moz-box-flex: $grow $shrink $basis;
  -webkit-flex: $grow $shrink $basis;
  -ms-flex:  $grow $shrink $basis;
  flex: $grow $shrink $basis;
  flex-basis: $basis;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@function get-value($n) {
    @return $n / ($n * 0 + 1);
}
@function get-line-height($font-size, $line-height) {
   @return get-value($line-height / $font-size);
}
