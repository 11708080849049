
/* Scroll to Content */
.ce_rsce_cc_02-01_scrollToContent {
	@include flex(0,1,100%);
	.container-custom{
		.row{
			.scroller-container{
				@include flex(0,1,100%);
			    padding-top: 30px;
			    padding-bottom: 30px;
				text-align: center;
				a{
					&:hover{
						opacity: .5;
					}
					img{
						width: 24px;
						height: 24px;
					}
				}
			}
		}
	}
}
/* BreadCrumb */
.mod_brod_top{
	margin-top: 0px;
	display: flex;
	flex-wrap: wrap;

	//background: $white;
	//padding-right: 0px;
	//padding-left: 0px;

	@include min(sm) {
		margin-top: -161px;
		//padding-right: 50px;
		//padding-left: 50px;
	}
	.container {
		margin-left: 10px;
	    width: 100%;
	    margin-right: 10px;
	    background: white;
	}

}




.mod_breadcrumb{
	@include padding;
	padding-bottom: 46px;
    margin: 0 auto;
	width: 100%;
    @include min(sm) {
        width: auto;
    }
	ul{
		padding: 0;
		margin: 0;
		li{
			text-transform: uppercase;
			float: left;
			.separator-icon{
				padding-right: 15px;
				padding-left: 15px;
			}
		}
	}
}


/* 3 BOXES */
.ce_rsce_cc_01-01_three_boxes {
	.headline{
		width: 100%;
		// text-align: center;
		@include font-size(2.2);
		color: $text-color;
		font-variation-settings: $bold;
		margin-top: 40px;
		margin-bottom: 40px;
		@include min(md) {
	        //margin-left: 37%;
	        //margin-right: 37%;
		}
	}

	.teaser-block {
		@include min(sm) {
		    padding-top: 15px;
		    padding-bottom: 15px;
		}
		@include min(md) {
		    @include flex(0,1,33.33%);
		}
		@include min(lg) {
		    padding-top: 30px;
		    padding-bottom: 30px;
		}
		h1, h2, h3, h4, h5, h6{
			font-variation-settings: $extra-bold;
			&.customPadHeadline{
				@include min(lg) {
					@include font-size(3.0);
				}
				@include min(xl) {
					@include font-size(4.0);
				}

			}
		}
		p {
			@include min(md) {
				margin-bottom: 2.5rem;
			}
		}
		/*
		&:nth-of-type(1){
			.customPadHeadline{
				margin-right: 0%;
			    margin-left: 0%;
				@include min(md) {
					margin-right: 25%;
				    margin-left: 25%;
				}
				@include min(lg) {
					margin-right: 18%;
				    margin-left: 18%;
				}
				@include min(xxl) {
					margin-right: 25%;
				    margin-left: 25%;
				}
			}
		}
		&:nth-of-type(2){
			.customPadHeadline{
				margin-right: 0%;
			    margin-left: 0%;
				@include min(md) {
					margin-right: 15%;
				    margin-left: 15%;
				}
				@include min(lg) {
					margin-right: 5%;
				    margin-left: 5%;
				}
				@include min(xxl) {
					margin-right: 10%;
				    margin-left: 10%;
				}
			}
		}
		&:nth-of-type(3){
			.customPadHeadline{
				margin-right: 0%;
			    margin-left: 0%;
				@include min(md) {
					margin-right: 0%;
				    margin-left: 0%;
				}
				@include min(lg) {
					margin-right: 0%;
				    margin-left: 0%;
				}
			}
		}
		*/
		// text-align: center;
		@include padding;
	    padding-top: $gutter;
	    padding-bottom: $gutter;
	    display: flex;
	    flex-flow: column nowrap;
		.button-block{
		    margin-top: auto;
		}
	}
}


/* News Teaser 66/33 */
.ce_rsce_cc_05_01_teaser_news {
	&:not(.first){
		margin-top: 40px;
	}
	.container-custom{
		.row{
			.teaser66{
    			display: flex;
    			@include flex(0,1,100%);

				@include min(md) {
					margin-right: 10px;
	    			@include flex(0,1,calc(66.667% - 10px));
				}

				.content-holder{
    				//@include flex(0,1,100%);
					padding: 52px 50px 52px 60px;
					background: $magenta-light;
					/*
					hyphens: auto;
					-webkit-hyphens: auto;
					-moz-hyphens: auto;
					-ms-hyphens: auto;
					-o-hyphens: auto;
					*/
					width: 100%;

					@include min(sm) {
						width: 50%;
					}
					p{
						color: $white;
					}
					h3{
						text-transform: uppercase;
					}
					.ce_text{
						margin-bottom: 40px;
					}

				}

				.image-block{
					display: none;
				    border: 2px solid $grey;

					@include min(sm) {
						width: 50%;
						display: block;
					}
					@include min(lg) {
						@include flex(0,1,50%);
					}
				}
				.image-holder{
					height: 100%;
					background-position: 50% 50%;
					background-repeat: no-repeat;
					background-size: cover;
					&.cover {
						background-size: cover;
					}
					&.contain {
						background-size: contain;
					}
				}
				&.is-mirror{
					order: 1;
					@include min(md) {
						margin-right: 0px;
						margin-left: 10px;
					}
				}

			}




			.teaser33{
    			@include flex(0,1,100%);
				border: 2px solid $grey;
				@include min(md) {
					@include flex(0,1,calc(33.333% - 10px));
					margin-left: 10px;
				}
				.content-holder{
					/*
					hyphens: auto;
					-webkit-hyphens: auto;
					-moz-hyphens: auto;
					-ms-hyphens: auto;
					-o-hyphens: auto;
					*/
					//padding: 20px 50px;
					padding: 52px 50px 52px 60px;
					h3{
						text-transform: uppercase;
					}
					.ce_text{
						margin-bottom: 40px;
					}
				}
				&.is-mirror{
					order: 0;
					@include min(md) {
						margin-right: 10px;
						margin-left: 0px;
					}
				}
			}
		}
	}
}




/* Big Text with Animated Background */
.ce_rsce_cc_17-01_ani_text{
	/*
	background: linear-gradient(to right, $magenta-light 50%, $white 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	@include transition(all, 2s ease-in);
	&.aniBackground{
		background-position: left bottom;
	}
	*/
	padding-top: 190px;
	padding-bottom: 190px;
	.teaser-block {
		@include flex(0,1,100%);
		@include min(md) {
			@include padding;
		}
		.inside{
			.ce_text{
				p{
					text-transform: uppercase;
					color: $black;
					margin: 0;
					line-height: 1;
					@include font-size(2.0);
					@include min(sm) {
						@include font-size(2.5);
					}
					@include min(lg) {
						@include font-size(3.5);
					}
					@include min(xl) {
						@include font-size(4.5);
					}
					@include min(max) {
						@include font-size(5.5);
					}
				}

			}
		}
	}
	
}







/* OUR EXPERTS */
.ce_rsce_cc_07-01_experts{
	padding-top: 40px;
	padding-bottom: 40px;
	@include min(sm) {
		padding-top: 190px;
		padding-bottom: 190px;
	}
	.teaser-block {
		@include flex(0,1,100%);
		@include min(md) {
			@include padding;
		}
		h1{
			color: white;
			margin-top: 0;
			margin-bottom: 0;
		}
		.button-block{
			margin-bottom: 0px;
			a{
				padding: 30px 60px;
				width: 100%;
				background-color: $magenta-light;
				letter-spacing: 1.6px;
				@include transition(all $speed ease-in);
				&:hover{
					//opacity: .5;
					background-color: $grey;
					color: $magenta-light;
				}
			}
		}
	}
	
}







.ce_rsce_cc_01-01_hero_teaser {
	padding: 0 !important;
	width: 100%;
	padding: 0;
	z-index: -10;
	.container-custom, .row {
		height: 100%;
	}
	.container-custom {
		//border: 1px solid white;
		margin: 0 auto;
		@include padding;
		width: 100%;
		@include min(sm) {
			width: calc(100% - 75px);
		}
		@include min(lg) {
			width: 1200px;
		}
		@include min(xl) {
			width: 1400px;
		}
	}
	.row {
		position: relative;
		padding-left: 0;
		padding-right: 0;
	}
	.image-holder {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		height: calc(100vh - 200px);
		@include min(sm) {
			height: calc(100vh - 140px);
		}
		@include min(lg) {
			height: calc(100vh - 140px);
		}
	}
	.content-holder {
		position: absolute;
		top: 0;
		width: 100%;
		@include min(sm) {
		}
		@include min(lg) {
		}
		@include min(xl) {
		}

		.headline {
			position: relative;
			width: 100%;
			color: $white;
			text-align: center;
			text-transform: uppercase;
			margin-top: 40px;
			margin-bottom: 40px;
			@include font-size(2.2);
			letter-spacing: 8.8px;
    		font-variation-settings: $extra-bold;
		}


		.subheadline {
			color: $white;
			position: absolute;
			@include min(sm) {
			}
			@include min(lg) {
			}
			@include min(xl) {
			}
			p.small{
				@include font-size(1.2);
				//padding-left: 5px;
				margin-top: 150px;
    			font-variation-settings: $extra-bold;
				text-transform: uppercase;
				@include min(sm) {
					@include font-size(2.2);
					letter-spacing: 8.8px;
					margin-top: 150px;
					//padding-left: 20px;
				}
				@media (max-height: 900px) {
					margin-top: 50px;
				}
				@media (max-height: 650px) {
					margin-top: 0px;
				}
				@media (max-height: 600px) {
					display: none;
				}
			}
			/*
			h1, h2, h3, h4, h5, h6{
				font-family: 'Roboto Slab';
    			font-variation-settings: $regular;
    			color: $white;

			}
			*/
			p.big{
				//font-family: 'Roboto Slab';
    			font-variation-settings: $semi-bold;
				@include font-size(10.0);
				margin: 0;
				margin-top: -20px;
				@include min(sm) {
					@include font-size(16.0);
				}
				@include min(md) {
					@include font-size(25.0);
					//margin-left: -6px;
				}
				@include min(lg) {
					@include font-size(30.0);
					margin-top: -100px;
				}
				@media (max-height: 900px) and (min-width: 767px) {
					@include font-size(12.8);
					margin-top: -60px;
				}
				@media (max-height: 650px) and (min-width: 767px) {
					@include font-size(12.8);
					margin-top: -60px;
				}
				@media (max-height: 600px) and (min-width: 767px) {
					display: none;
				}
			}



		}
	}
}




.ce_rsce_cc_01-02_hero_slider {
	padding: 0 !important;
	width: 100%;
	padding: 0;
	//z-index: -10;
	.container-custom, .row {
		height: 100%;
	}
	.container-custom {
		margin: 0 auto;
		@include padding;
		width: 100%;
		@include min(sm) {
			width: calc(100% - 75px);
		}
		@include min(lg) {
			width: 1200px;
		}
		@include min(xl) {
			width: 1400px;
		}
	}
	.row {
		position: relative;
		padding-left: 0;
		padding-right: 0;
	}
	.image-holder {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		@include min(sm) {
			height: calc(100vh - 140px);
		}
	}
	.content-holder {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
            margin-bottom: auto;
            margin-top: auto;

        h1, h2, h3, h4, h5, h6 {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: 'Roboto Slab';
            font-variation-settings: $light;
            line-height: 1;
            //margin-bottom: auto;
            //margin-top: auto;
            margin: 0;
            text-transform: uppercase;
            @include font-size(1.6);
            color: $white;
            text-align: left;
            padding-left: 5%;
            padding-right: 5%;
            width: 100%;
            @include min(sm) {
                @include font-size(4);
            }
            @include min(md) {
                @include font-size(6);
            }
            @include min(lg) {
                @include font-size(7);
            }
            @include min(xxl) {
                @include font-size(8);
            }
        }
        p {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: 'Roboto Slab';
            font-variation-settings: $light;
            line-height: 1;
            //margin-bottom: auto;
            //margin-top: auto;
            margin: 0;
            text-transform: uppercase;
            @include font-size(1.6);
            color: $white;
            text-align: left;
            padding-left: 5%;
            padding-right: 5%;
            width: 100%;
        }
	}
}














/* Footer-Top Element */
/*
.footer-top{
	color: $black;
	@include font-size(1.6);
	text-transform: uppercase;
	font-variation-settings: 'wght' 800;

	@include min(sm) {
		@include font-size(3.0);
	}
	@include min(md) {
		@include font-size(4.0);
	}
	@include min(lg) {
		@include font-size(6.0);
	}


}
*/

/* CONTACT INFO*/
.ce_rsce_cc_22-01_contact_info {
@include flex(0,1,100%);
	.container-custom{
		.row{
			.inside{
				@include flex(0,1,100%);
				margin-left: 10px;
				margin-right: 10px;
				background: $white;
				@include min(sm) {
					padding-bottom: 80px;
					padding-right: 50px;
					padding-left: 50px;
				}
				.headline{
					text-transform: uppercase;

				}
				.teaser{
					display: flex;
					flex-flow: wrap;
					.teaser-block{
						@include flex(0,1,100%);
						@include min(sm) {
							@include flex(0,1,50%);
						}
						@include min(lg) {
							@include flex(0,1,25%);
						}
						h4{
							//text-transform: uppercase;
							color: $magenta-light;
						}
						a{
							color: $text-color;
						    &:hover{
								color: $hover;
						    }

						}
					}
				}
			}
		}
	}


}





/* CONTACT READER*/
.ce_rsce_cc_03-01_contact_reader {

	@include min(sm) {
		&.special_bg{
			background: linear-gradient(180deg, rgba(255, 50, 79, 1) 0%, rgba(195, 11, 61, 1) 100%);
		}
	}

	&.fadeout{
		.ce_text{
			p{
				@include min(md) {
					&:after {
			            content: '';
			            position: absolute;
			            bottom: 120px;
			            width: 50%;
			            left: 50%;
			            height: 40%;
			            background: -webkit-linear-gradient(
			                    rgba(255, 255, 255, 0) 0%,
			                    rgba(255, 255, 255, 1) 100%
			            );
			            background-image: -moz-linear-gradient(
			                    rgba(255, 255, 255, 0) 0%,
			                    rgba(255, 255, 255, 1) 100%
			            );
			            background-image: -o-linear-gradient(
			                    rgba(255, 255, 255, 0) 0%,
			                    rgba(255, 255, 255, 1) 100%
			            );
			            background-image: linear-gradient(
			                    rgba(255, 255, 255, 0) 0%,
			                    rgba(255, 255, 255, 1) 100%
			            );
			            background-image: -ms-linear-gradient(
			                    rgba(255, 255, 255, 0) 0%,
			                    rgba(255, 255, 255, 1) 100%
			            );
			        }
				}

			}
		}
	}
	.teaser-block{
	    background: $white;
		padding-bottom: 20px;
		@include min(sm) {
			padding-bottom: 80px;
			padding-right: 50px;
			padding-left: 50px;
		}
		.inside{
			@include min(sm) {
				@include padding;
				display: flex;
			}
			h1, h2{
				&.headline{
					text-transform: uppercase;
					@include min(sm) {
						@include flex(0,1,50%);
						padding-right: 20px;
						margin-top: 16px;
					    margin-bottom: 16px;
					}
					@include min(xxl) {
						padding-right: 5%;
					}
				}
			}
			h3, h4, h5, h6{
				&.headline{
					text-transform: uppercase;
					@include min(sm) {
						@include flex(0,1,50%);
						padding-right: 5%;
					}
					@include min(lg) {
						padding-right: 10%;
					}
					@include min(xxl) {
						padding-right: 20%;
					}
				}
			}

			.ce_text{
				@include min(sm) {
					@include flex(0,1,50%);
					//@include padding;
				}
			}
			.button-block{
				.button{
					background: none;
					border: 1px solid $grey;
					color: $magenta-light;
					letter-spacing: 2.8px;
					padding: 8px 120px;
				    margin-top: 50px;
				    @include transition(color, border, $speed ease-in);
				    &:hover{
						border: 1px solid $magenta-light;
						color: $black;
				    }
				}
			}


		}
	}

}
/* CONTACT READER 02 */
.ce_rsce_cc_03-02_contact_reader_big_text{
	&.white{
		.container-custom {
			padding: 0;
		}
	}
	.container-custom {
		padding: 0;
		.row{
			margin: 0 !important;
			.teaser-block{
				background: $white;
				padding-bottom: 80px;
				margin-left: 10px;
				margin-right: 10px;
				@include min(sm) {
					padding-right: 50px;
					padding-left: 50px;
				}
				.inside{
					@include min(sm) {
						//@include padding;
						display: flex;
					}

					.headline{
						text-transform: uppercase;
						@include min(sm) {
							@include flex(0,1,50%);
							padding-right: 5%;
						}
						@include min(lg) {
							padding-right: 10%;
						}
						@include min(xxl) {
							padding-right: 20%;
						}
					}


					.ce_text{
						h3{
							text-transform: uppercase;
							font-variation-settings: $regular;
							@include font-size(2);
							@include min(sm) {
								@include font-size(2);
							}
							@include min(md) {
								@include font-size(3);
							}
							@include min(lg) {
								@include font-size(4);
							}
							@include min(xl) {
								@include font-size(5);
							}
							@include min(max) {
								@include font-size(6);
							}

						}
						p, .button-block{
							@include min(lg) {
								padding-right: 33%;
							}
						}
					}
					.button-block{
						.button{
							background: none;
							border: 1px solid $grey;
							color: $magenta-light;
							letter-spacing: 2.8px;
							padding: 8px 80px;
							text-align: center;
							width: 100%;
						    @include transition(color, border, $speed ease-in);
							@include min(sm) {
						    	margin-top: 50px;
								padding: 8px 120px;
								width: auto;
							}
						    &:hover{
								border: 1px solid $magenta-light;
								color: $black;
						    }
						}
					}


				}
			}

		}
	}

}


.ce_rsce_cc_04-01_contact_options {
	display: flex;
	padding-top: 80px;
	padding-bottom: 80px;
	.teaser-headline{
		@include flex(0,1,100%);
		@include padding;
		margin-bottom: 20px;
		.headline{
			display: inline-block;
			text-transform: uppercase;
			@include font-size(2);
			color: $black;

			@include min(sm) {
    			color: $white;
			}
			@include min(md) {
				letter-spacing: 5px;
			}
			&:after {
			    content: '';
			    height: 1px;
			    background: $orange;
			    display: block;    
			    margin-top: 4px;
			}
		}
	}
	.teaser-block{
	    //background: $white;
	    @include flex(0,1,100%);
		margin-bottom: 20px;
		@include min(sm) {
			@include flex(0,1,50%);
			@include padding;
		}
		@include min(lg) {
	    	margin-bottom: 60px;
		}
		.inside{
	    	background: $magenta-contact;
			@include min(sm) {
				//@include padding;
			}
			.image-holder{
				.image_container{
					min-height: 320px;
				}
			}
			.content-holder{
				padding-top: 20px;
				padding-bottom: 50px;
				padding-right: 50px;
				padding-left: 50px;

				@include min(sm) {
					padding-top: 20px;
					padding-bottom: 50px;

					padding-right: 50px;
					padding-left: 50px;
					margin-left: 5px;
					margin-right: 5px;
				}
				@include min(lg) {
					margin-left: 15px;
					margin-right: 15px;
				}
				.headline{
					text-transform: uppercase;
		    		color: $white;
					@include min(sm) {
					}
					@include min(lg) {
					}
					@include min(xxl) {
					}
				}


				.ce_text{
					@include min(sm) {
						@include flex(0,1,50%);
						//@include padding;
					}
				}

			}

		}
	}

}



/* Agentur */
.ce_rsce_cc_06-01_agentur_people {

	@include min(sm) {
		&.special_bg{
			background: linear-gradient(180deg, rgba(195, 11, 61, 1) 0%, rgba(173, 0, 58, 1) 40%, rgba(173, 0, 58, 1) 60%, rgba(186, 0, 59, 1) 100%);
		}
	}

	.container-custom{
		.row{
			.teaser{
    			@include flex(0,1,100%);
				padding-top: 50px;
				padding-bottom: 50px;
				background: $white;


				@include min(sm) {
    				display: block;
					margin-right: 5px;
    				margin-left: 5px;
				}


				@include min(lg) {
					margin-right: 15px;
					margin-left: 15px;
					padding-right: 50px;
	 				padding-left: 50px;
				}

				@include min(xl) {
    				display: flex;
				}





				.teaser-block{
					background: $white;
	    			display: grid;
	    			@include flex(0,1,100%);

					@include min(sm) {
	    				display: flex;
					}

					@include min(sm) {
						&:nth-of-type(2){
							margin-top: 40px;
						}
					}


					@include min(xl) {
						&:nth-of-type(1){
							margin-right: 15px;
						}
						&:nth-of-type(2){
							margin-left: 15px;
							margin-top: 0px;
						}
					}

					.content-holder{
						@include flex(0,1,100%);
						border-top: 1px solid $grey;
						border-bottom: 1px solid $grey;
						hyphens: auto;
						-webkit-hyphens: auto;
						-moz-hyphens: auto;
						-ms-hyphens: auto;
						-o-hyphens: auto;
						order: 1;

						@include min(sm) {
							padding: 52px 50px 52px 60px;
							order: 0;
						}
						@include min(md) {
							@include flex(0,1,50%);
						}

						@include min(xl) {
							@include flex(0,1,55%);
						}
						p{
							text-transform: uppercase;
						}
						h3{
							text-transform: uppercase;
						}
						.ce_text{
							margin-bottom: 40px;
						}

					}

					.image-block{
						@include flex(0,1,100%);
						
						@include min(md) {
							@include flex(0,1,50%);
						}

						@include min(xl) {
							@include flex(0,1,45%);
						}
					}
					.image-holder{
						height: 100%;
						background-position: 50% 50%;
						background-repeat: no-repeat;
						background-size: cover;

						min-height: 320px;
						order: 0;

						@include min(sm) {
							min-height: auto;
							order: 1;
						}

					}

				}
			}
		}
	}
}



/* Agentur CHEFS 02 NEW */
.ce_rsce_cc_06-02_agentur_people_half {
	padding-bottom: 80px;
	padding-top: 80px;
	.container-custom{
		.row{
			.teaser{
				@include min(sm) {
    				display: flex;
					@include flex(0,1,100%);
				}
				@include min(lg) {
					margin-right: 15px;
					margin-left: 15px;
				}
				.teaser-block{
					@include flex(0,1,100%);
					@include min(sm) {
						@include flex(0,1,50%);
						&:nth-of-type(1){
							margin-right: 40px;
						}

						&:nth-of-type(2){
							margin-left: 40px;
						}
					}
					.content-holder{
						@include min(sm) {
							//padding: 52px 50px 52px 60px;
						}

						h3{
							text-transform: uppercase;
							@include font-size(1.4);
							font-variation-settings: $regular;
							@include min(sm) {
								@include font-size(1.8);
								margin-top: 20px;
								margin-bottom: 40px;
							}
						}

						p.dep{
							text-transform: uppercase;
							color: $black;
							font-variation-settings: $bold;
							@include font-size(2.0);
							@include min(sm) {
								@include font-size(2.8);
								margin: 0;
							}

						}
						h4{
							text-transform: uppercase;
							@include font-size(1.4);
							color: $magenta-light;
							font-variation-settings: $regular;
							@include min(sm) {
								@include font-size(1.8);
								margin: 0;
								letter-spacing: 4px;
							}
						}
						.ce_text.quote{
							margin-top: 30px;
							@include min(lg) {
								padding-right: 20%;
							}
						}
						.ce_text.boost{
							p{
								margin: 0;
							}
							margin-top: 40px;
							margin-bottom: 40px;
							text-transform: uppercase;
							font-variation-settings: $light;
							@include font-size(2.0);
							@include min(sm) {
								@include font-size(2.2);
							}
							@include min(md) {
								@include font-size(2.8);
							}
						}

						.button-block{
							.button{
								background: none;
								border: 1px solid $grey;
								color: $magenta-light;
								letter-spacing: 2.8px;
								padding: 8px 80px;
								width: 100%;
								text-align: center;
							    @include transition(color, border, $speed ease-in);
								@include min(md) {
									padding: 8px 120px;
									width: auto;
								}
							    &:hover{
									border: 1px solid $magenta-light;
									color: $black;
							    }
							}
						}

					}

					.image-block{
						.image-holder{
							height: 100%;
							background-position: 50% 50%;
							background-repeat: no-repeat;
							background-size: cover;
							min-height: 320px;
							@include min(sm) {
								min-height: 480px;
							}
							@include min(md) {
								min-height: 630px;
							}
							@include min(lg) {
								min-height: 840px;
							}

						}
					}

				}
			}
		}
	}
}


/* TEAM */
.ce_rsce_cc_18-01_agentur_team {
	.container-custom{
		.row{
			.teaser{
    			@include flex(0,1,100%);
				@include min(sm) {
					@include padding;
				}

				.teaser-block{
	    			//display: grid;
	    			//@include flex(0,1,100%);
					padding-top: 50px;
					padding-bottom: 50px;

					@include min(sm) {
	    				display: flex;
					}



					&:nth-child(odd){
						@include min(sm) {
							.content-holder{
								@include min(sm) {
									padding-right: 40px;
								}
								@include min(md) {
									padding-right: 20%;
								}
							}
						}
					}
					&:nth-child(even){
						@include min(sm) {
							.content-holder{
								order: 1;
								@include min(sm) {
									padding-left: 40px;
								}
								@include min(md) {
									padding-left: 20%;
								}
							}
							.image-block{
								order: 0;
							}
						}
					}

					.content-holder{
						@include flex(0,1,100%);
						@include min(sm) {
							@include flex(0,1,50%);
							//@include flex(0,1,66.667%);
						}
						@include min(md) {
							@include flex(0,1,66.667%);
						}

						h3{
							text-transform: uppercase;
							@include font-size(1.4);
							font-variation-settings: $regular;
							@include min(sm) {
								@include font-size(1.8);
								margin-top: 20px;
								margin-bottom: 40px;
							}
						}

						p.dep{
							text-transform: uppercase;
							color: $black;
							font-variation-settings: $bold;
							@include font-size(2.0);
							@include min(sm) {
								@include font-size(2.8);
								margin: 0;
							}

						}
						h4{
							text-transform: uppercase;
							@include font-size(1.4);
							color: $magenta-light;
							font-variation-settings: $regular;
							@include min(sm) {
								@include font-size(1.8);
								margin: 0;
								letter-spacing: 4px;
							}
						}
						.ce_text.quote{
							margin-top: 30px;
						}
						.ce_text.boost{
							p{
								margin: 0;
							}
							margin-top: 40px;
							margin-bottom: 40px;
							text-transform: uppercase;
							font-variation-settings: $light;
							@include font-size(2.0);
							@include min(sm) {
								@include font-size(2.2);
							}
							@include min(md) {
								@include font-size(2.8);
							}
						}

						.button-block{
							.button{
								background: none;
								border: 1px solid $grey;
								color: $magenta-light;
								letter-spacing: 2.8px;
								padding: 8px 80px;
								width: 100%;
								text-align: center;
							    @include transition(color, border, $speed ease-in);
								@include min(md) {
									padding: 8px 120px;
									width: auto;
								}
							    &:hover{
									border: 1px solid $magenta-light;
									color: $black;
							    }
							}
						}

					}

					.image-block{
						@include flex(0,1,100%);
						@include min(sm) {
							@include flex(0,1,50%);
						}
						@include min(md) {
							@include flex(0,1,33.333%);
						}
						.image-holder{
							height: 100%;
							background-position: 50% 50%;
							background-repeat: no-repeat;
							background-size: cover;
							min-height: 520px;
						}




					}

				}
			}
		}
	}
}




/* Agentur */
.ce_rsce_cc_08-01_agentur_general {

	@include min(sm) {
		&.special_bg{
			background: linear-gradient(180deg, rgba(186, 0, 59, 1) 0%, rgba(207, 0, 60, 1) 100%);
		}
	}

	.container-custom{
		.row{
			.teaser{
    			@include flex(0,1,100%);
				padding-top: 50px;
				padding-bottom: 150px;
				background: $white;

				@include min(sm) {
    				display: block;
				}
				@include min(lg) {
					padding-right: 50px;
	 				padding-left: 50px;
				}
				@include min(xl) {
    				display: flex;
				}

				.teaser-block{
					background: $white;
	    			display: grid;
	    			@include flex(0,1,100%);

					@include min(sm) {
	    				display: flex;
					}

					.content-holder{
						@include flex(0,1,100%);
						border-top: 1px solid $grey;
						border-bottom: 1px solid $grey;
						hyphens: auto;
						-webkit-hyphens: auto;
						-moz-hyphens: auto;
						-ms-hyphens: auto;
						-o-hyphens: auto;
						order: 1;

						@include min(sm) {
							padding: 52px 50px 52px 60px;
							order: 0;
						}
						@include min(md) {
							@include flex(0,1,50%);
						}

						@include min(xl) {
							@include flex(0,1,calc(27.5% - 7.5px));
						}
						p{
							text-transform: uppercase;
						}
						h3{
							text-transform: uppercase;
						}
						.ce_text{
							margin-bottom: 40px;
						}

					}

					.image-block{
						@include flex(0,1,100%);
						
						@include min(md) {
							@include flex(0,1,50%);
						}

						@include min(xl) {
							@include flex(0,1,calc(72.5% + 7.5px));
						}
					}
					.image-holder{
						height: 100%;
						background-position: 50% 50%;
						background-repeat: no-repeat;
						background-size: cover;

						min-height: 320px;
						order: 0;

						@include min(sm) {
							min-height: auto;
							order: 1;
						}

					}

				}
			}
		}
	}
}


.ce_rsce_cc_09-01_service{
	&.special_bg{
		background: linear-gradient(180deg, rgba(207, 0, 60, 1) 0%, rgba(217, 3, 59, 1) 100%);
	}
	padding-top: 40px;
	padding-bottom: 40px;
	.teaser-block {
		@include flex(0,1,100%);
		@include min(md) {
			@include padding;
			padding-top: 190px;
			padding-bottom: 190px;
		}
		.headline{
			color: white;
			@include font-size(1.4);
			letter-spacing: 2.8px;
			text-transform: uppercase;
		}
		h1{
			color: white;
			margin-top: 0;
			margin-bottom: 0;
		}
		.ce_text{
			@include min(md) {
				float: left;
			}
		}
		.button-block{
			margin-bottom: 0px;
			margin-top: 40px;


			@include min(md) {
				position: absolute;
		    	width: 55%;
				margin-top: 0px;
			    right: 0px;
			}
			@include min(lg) {
				margin-top: 128px;
		    	//width: 18%;
		    	width: 32%;
			}
			@include min(xl) {
		    	//width: 30%;
		    	width: 40%;
			}
			@include min(xxl) {
		    	//width: 38%;
		    	width: 48%;
			}
			@include min(max) {
		    	//width: 40%;
		    	width: 48%;
			}



			a{
				padding: 35px 58px;
				width: 100%;
				background-color: $magenta-light;
				letter-spacing: 2.8px;
				@include transition(all $speed ease-in);
				&:hover{
					//opacity: .5;
					background-color: $grey;
					color: $magenta-light;
				}
			}
		}
	}
	
}
/* FACTS */
.ce_rsce_cc_20-01_facts {
	@include flex(0,1,100%);
	border-bottom: 1px solid $grey;
	padding-left: 10px;
	padding-right: 10px;
	@include min(sm) {
		border-bottom: none;
		display: flex;
	}
	.container-custom{
		.row{
			.teaser{
				@include min(sm) {
					display: flex;
					@include flex(0,1,100%);
				}

				/*
				.counter-block{
					@include flex(0,1,100%);
					@include min(sm) {
						margin-top: auto;
						@include flex(0,1,33.333%);
					}
					.content-holder{
						p{
							@include font-size(2.0);
							@include min(sm) {
								//float: right;
								margin-bottom: 0;
								@include font-size(13.0);
								text-align: center;
							}
						}
					}
				}
				*/

				.teaser-block{
					@include flex(0,1,100%);
					@include min(sm) {
						//@include flex(0,1,66.667%);
					}

					.content-holder{
						@include min(sm) {
							//float: right;
						}
						p.number{
							text-transform: uppercase;
							font-variation-settings: $mega-bold;
							color: $magenta-light;
							margin: 0;
							@include font-size(6);

							@include min(sm) {
								@include font-size(10.0);
							}
							@include min(md) {
								@include font-size(16.0);
							}
							@include min(lg) {
								@include font-size(20.0);
							}
							@include min(xl) {
								@include font-size(24.0);
							}

						}
						.bigBlack{
							p{
								text-transform: uppercase;
								font-variation-settings: $mega-bold;
								color: $black;
								margin: 0;
								@include font-size(6);
								line-height: 1;

								@include min(sm) {
									@include font-size(8);
									margin-top: -20px;
								}
								@include min(md) {
									@include font-size(10);
									margin-top: -40px;
								}
								@include min(lg) {
									@include font-size(12);
									margin-top: -60px;
								}
								@include min(xl) {
									@include font-size(14);
									margin-top: -80px;
								}
							}
						}


						.ce_text{
							p{
								//text-transform: uppercase;
								font-variation-settings: $mega-bold;
								letter-spacing: 1.2px;
								
								@include font-size(2.0);
								margin-top: -5px;

								@include min(sm) {
									@include font-size(2.1);
									margin-top: -10px;
								}
								@include min(md) {
									@include font-size(2.2);
								}
								@include min(lg) {
									@include font-size(2.3);
									margin-top: -20px;
								}
								@include min(xl) {
									@include font-size(2.4);
								}
							}
						}

					}


				}



				&.is_Mirror{
					.counter-block{
						order: 1;
					}
					.teaser-block{
						order: 0;
						.content-holder{
							//float: right;
							@include min(sm) {
								margin-left: 50%;
							}


							.ce_text{
								p{
									@include min(sm) {
	    								margin-left: 0px;
									}
								}
						}
						}
					}
				}











			}

		}
	}
}












































































































































/* LEISTUNGEN/SERVICES BRAINS */

.ce_rsce_cc_13-01_brains_titles {
	padding-bottom: 50px;
	display: flex;
	@include flex(0,1,100%);
	.teaser-block {
		@include flex(0,1,100%);
		@include min(md) {
			//@include padding;
		}
		h1{
			@include font-size(1.6);
			@include min(sm) {
				color: $grey;
				text-align: center;
				@include font-size(2.0);
				letter-spacing: 4px;
			}
			@include min(md) {
				@include font-size(2.2);
				letter-spacing: 8.8px;
			}
		}
		.ce_text{
			h2{
				@include font-size(4.0);
				margin-top: 0;
				margin-bottom: 0;
				@include min(sm) {
					//color: $white;
					@include font-size(10.0);
				}
				@include min(md) {
					@include font-size(17.0);
					padding-top: 50px;
				}

			}
			.specialText{
				@include min(md) {
					margin-top: -20px;
    				font-size: 28px;
   					padding-left: 70px;
				}
			
			}

		}
	}
}


/* Brain Teaser */
.ce_rsce_cc_16-01_brains_teaser{
	.teaser-block{
		margin-bottom: 30px;
		width: 100%;
		@include min(sm) {
			margin-left: 5px;
			margin-right: 5px;
		}
		@include min(md) {
			display: flex;
		}
		@include min(lg) {
			margin-left: 15px;
			margin-right: 15px;
		}
		.inside{
			@include min(sm) {
				padding: 94px 104px;
				padding-bottom: 0;
				padding-top: 0px;
			}
			@include min(md) {
				padding-top: 0px;
				padding-bottom: 94px;
				padding-left: 104px;
				padding-right: 104px;
				width: calc(100% - 78px);
			}
			.boxMain{
				.headline_zitat{
					text-transform: uppercase;
					letter-spacing: 2.4px;
					color: $black;
					@include font-size(2.0);
					line-height: 1;
					@include min(sm) {
						@include font-size(3.0);
					}
					@include min(md) {
						@include font-size(5.0);
					}
					@include min(xl) {
						padding-right: 25%;
					}
				}
				.ce_text{
					@include min(md) {
						padding-right: 33.333%;
					}
				}
			}
		}
	}
}




/* Brain Sections */
.ce_rsce_cc_14-01_brains_sections {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	&.first, &.last{
		padding-top: 40px;
		padding-bottom: 40px;
	}
	@include min(sm) {
		&.first, &.last{
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}
	@include min(md) {
		&.first, &.last{
			padding-top: 100px;
			padding-bottom: 100px;
		}
	}
	.teaser-block{
		width: 100%;
		margin-left: 10px;
		margin-right: 10px;
		@include min(sm) {
			background:rgba(255,255,255,.05);
			backdrop-filter: blur(4px);
		}
		@include min(md) {
			display: flex;
		}
		.inside{
			padding: 40px 20px;
			@include min(sm) {
				padding: 94px 104px;
			}
			@include min(md) {
				width: calc(100% - 78px);
			}
			.boxMain{
				.headline{
					text-transform: uppercase;
					margin: 0;
					@include font-size(1.6);
					letter-spacing: 2.4px;
					color: $magenta-light;
				}
				.ce_text{
					.teaser{
						@include font-size(2.2);
						@include min(md) {
							padding-right: 15%;
						}
					}
					h3{
						text-transform: uppercase;
						hyphens: auto;
						color: $white;
						@include font-size(3.0);
						@include min(sm) {
							@include font-size(5.0);
							margin-bottom: 20px;
						}
					}
					h4{
						margin-top: 16px;
						letter-spacing: 2.4px;
						@include min(md) {
							margin-top: 40px;
						}

						color: $magenta-light;
						text-transform: uppercase;
						@include font-size(1.6);
						@include min(sm) {
							@include font-size(1.6);
							margin-bottom: 10px;
						}
					}
					table{
						tr{
							td{
								width: 100%;
								float: left;
								@include min(sm) {
									//width: 50%;
								}

								ul{
									margin-top: 0px;
									padding-left: 16px;
									@include min(sm) {
										margin-top: 16px;
										margin-bottom: 16px;
									}
									li{
										&:before{
										    display: none;
										}
										@include min(md) {
											&:before{
												content: '';
											    display: block;
											    width: 5px;
											    height: 5px;
											    border-radius: 50%;
											    position: absolute;
											    top: 8px;
											    left: -15px;
											    background: $magenta-light;
											}
										}
									}
								}
							}
						}
					}


					.boxTwo{
						display: block;
						@include min(md) {
							display: flex;
							//margin-top: 40px;
						}

						.two{
							@include flex(0,1,100%);
							text-align: center;
							border: 1px solid $grey;
							margin-bottom: 20px;
							padding: 20px 20px;

							@include min(md) {
								text-align: left;
								border: none;
								margin-bottom: 0px;
								padding: 0;
								@include flex(0,1,50%);
								margin-right: 50px;
								margin-left: 20px;
								display: flex;
 								flex-flow: column nowrap;
 								&:first-child{
 									margin-left: 0px;
 								}
 								&:last-child{
 									margin-right: 0px;
 								}
							}
							ul{
								margin-top: 0px;
							}
							.button-block{
								margin-top: auto;
								margin-left: 0;
								.button{
									padding: 5px 70px;
									border: 1px solid $grey;
									background-color: transparent;
					    			@include transition(all $speed ease-in);
								    &:hover{
										color: $magenta-light;
										border: 1px solid $magenta-light;
								    }
								}
							}
						}
					}



					.boxThree{
						display: block;
						@include min(md) {
							display: flex;
							//margin-top: 40px;
						}

						.three{
							@include flex(0,1,100%);
							text-align: center;
							border: 1px solid $grey;
							margin-bottom: 20px;
							padding: 20px 20px;

							@include min(md) {
								text-align: left;
								border: none;
								margin-bottom: 0px;
								padding: 0;
								@include flex(0,1,33.333%);
								margin-right: 50px;
								margin-left: 20px;
								display: flex;
 								flex-flow: column nowrap;
 								&:first-child{
 									margin-left: 0px;
 								}
 								&:last-child{
 									margin-right: 0px;
 								}

							}
							.button-block{
								margin-top: auto;
								margin-left: 0;
								.button{
									padding: 5px 70px;
									border: 1px solid $grey;
									background-color: transparent;
					    			@include transition(all $speed ease-in);
								    &:hover{
										color: $magenta-light;
										border: 1px solid $magenta-light;
								    }
								}
							}
							&.noDisplay{
								//opacity: 0;
								display: none;
								@include min(md) {
									display: flex;
								}

							}
						}
					}

				}
			}
		}

		/*
		.boxRight{
			@include min(md) {
				border: 1px solid $grey;
				//@include flex(0,1,87px);
				width: 87px;
			}

			.deg-90{
				margin-top: 40px;
				width: auto;
				text-align: center;
				margin-bottom: 40px;
				@include min(sm) {
					margin-top: 40px;
					margin-bottom: 94px;
					margin-right: 104px;
					margin-left: 104px;
				}

				@include min(md) {
					text-align: left;
					margin: 0;
					width: 100%;
				    height: 100%;
					.button{
						color: $white;
						padding: 33px 50px;
						height: 87px;
						@include font-size(1.6);
						letter-spacing: 2.4px;
						text-align: center;
					    @include transition(background $speed ease-in);

					    position: relative;
					    left: 87px;

					   	transform-origin: top left;
						transform: rotate(90deg) translateX(0%);
						-ms-transform: rotate(90deg translateX(0%));
						-webkit-transform: rotate(90deg) translateX(0%);
						-o-transform: rotate(90deg) translateX(0%);


					    &:hover{
							background: $magenta-light;
					    }
					}
				}
			}
		}
		*/



	}
}



/* ANCHOR NAV */
body {
    &.anchor-nav-fixed {
        .ce_rsce_cc_15-01_anchornav {
            margin-top: 0px;
            position: fixed;
            left: 0;
            width: 100%;
            z-index: 10;
        }
    }
	.ce_rsce_cc_15-01_anchornav {
		z-index: 2;
	    background: $white;
	    border-bottom: 1px solid $grey;
	    width: 100%;
	    margin-top: -50px;
	    @include transition(margin .5s ease-in);
	    position: fixed;
	    left: 0;
		@include max(992px) {
			display: none;
		}
		.anchor-nav{
			display: flex;
			margin: 0;
			padding-top: 10px;
			padding-bottom: 10px;
	        //border: 1px solid black;
			li {
				padding: 0 20px;
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
				&:before{
					display: none;
				}
			}
			a.anchor {
				position: relative;
				padding-top: 12px;
				padding-bottom: 12px;
				@include font-size(1.4);
				letter-spacing: 0.7px;
				color: $grey;
				text-transform: uppercase;

				&:hover, &.active {
					color: $magenta-light;
				}
			}
		}
	}
}


/* SPECIAL HEADLINE with text and link */
.ce_rsce_cc_17-01_special_headline{

	width: 100%;
	@include min(sm) {
		@include padding;
	}
	.teaser-block{
		width: 100%;
		//padding-bottom: 40px;
		@include min(sm) {
			//padding-bottom: 60px;
		}
		.inside{
			.headline, h1, h2, h3, h4, h5, h6{
				font-family: 'Roboto Slab';
    			font-variation-settings: $regular;
				color: $magenta-light;
				text-transform: uppercase;
				text-align: center;
			}
			h1, h2{
				@include min(sm) {
					margin-top: 60px;
					margin-bottom: 60px;
				}
			}
			.button-block{
				text-align: center;
			}
			/* Font-Family */
			h1, h2, h3, h4, h5, h6{
				&.heebo{
					font-family: 'Heebo';
				}
				&.slab{
					font-family: 'Roboto Slab';
				}
			}
			/* Font-Color */
			h1, h2, h3, h4, h5, h6{
				&.magenta-light{
					color: $magenta-light;
				}
				&.black{
					color: $black;
				}
				&.grey{
					color: $text-color;
				}
				&.white{
					color: $white;
				}
			}


		}
	}

	&.headline{
		.inside{
			h1, h2, h3, h4, h5, h6{
				position: relative;
			    width: 100%;
			    color: $magenta-light;
			    text-align: center;
			    text-transform: uppercase;
			    margin-top: 40px;
			    margin-bottom: 40px;
			    font-size: 22px;
			    font-size: 2.2rem;
			    letter-spacing: 8.8px;
			    font-variation-settings: 'wght' 800;
			}
		}
	}




}


















































































/* CONTACT MODULE */

/* Contact Formlar */
.ce_rsce_cc_21-01_contact {
	//padding-top: 40px;
	padding-bottom: 100px;
	width: 100%;
	.container-custom{
		.row{
			.teaser{
    			display: contents;
    			margin-left: 10px;
    			margin-right: 10px;
				background: $white;
				@include min(sm) {
    				display: flex;
    				@include flex(0,1,100%);
				}
				.content-holder{
					@include flex(0,1,100%);
					order: 1;
					@include min(sm) {
						order: 0;
						@include flex(0,1,50%);
						border-top: 1px solid $grey;
						border-bottom: 1px solid $grey;
						border-left: 1px solid $grey;
						padding: 70px 65px 70px 65px;
						h1, h2, h3, h4, h5, h6{
							margin-top: 0;
							margin-bottom: 0;
						}
						p{
							margin-top: 20px;
							margin-bottom: 30px;

						}
					}
				}
				.image-block{
					@include flex(0,1,100%);
					order: 0;    
					margin-bottom: 40px;
    				margin-top: 40px;
					@include min(sm) {
						margin-bottom: 0px;
	    				margin-top: 0px;
						@include flex(0,1,50%);
						order: 1;
					}
					.image-holder{
						height: 100%;
						background-position: 50% 50%;
						background-repeat: no-repeat;
						background-size: cover;
						min-height: 320px;
					}



				}

			}
		}
	}
	&.is-mirror{
		.container-custom{
			.row{
				.teaser{
					.content-holder{
						@include min(sm) {
							order: 1;
						}
					}
					.image-block{
						@include min(sm) {
							order: 0;
						}

					}
				}
			}
		}

	}
}






/* WORK */
.ce_rsce_cc_10-01_work_titles{
	//padding-top: 50px;
	//padding-bottom: 50px;
	display: flex;
		@include flex(0,1,100%);
	.teaser-block {
		@include flex(0,1,100%);
		@include min(md) {
			//@include padding;
		}
		h1{
			color: $grey;

			@include font-size(1.6);
			@include min(sm) {
				text-align: center;
				@include font-size(2.0);
				letter-spacing: 10px;
			}
			@include min(md) {
				@include font-size(2.2);
				letter-spacing: 20px;
				margin-top: 40px;
				margin-bottom: 40px;
			}
		}
		.ce_text{
			h2{
				//font-family: 'Roboto Slab';
    			//font-variation-settings: $regular;
    			font-variation-settings: $extra-bold;
				@include font-size(4.0);
				//margin-top: 0;
				//margin-bottom: 0;
				@include min(sm) {
					@include font-size(10.0);
				}
				@include min(md) {
					@include font-size(17.0);
				}

			}

		}
	}
	
}

.breadcrumPadding{
	margin-top: 40px;
	margin-bottom: 40px;
	@include min(sm) {
		margin-top: 100px;
		margin-bottom: 100px;
	}
}



.ce_rsce_cc_11-01_title_text_reader {
	margin: 0 auto;
	.teaser-block{
	    background: $white;
		padding-bottom: 20px;

		@include min(sm) {
			padding-bottom: 80px;
			margin-left: 15px;
			margin-right: 15px;
		}
		@include min(md) {
			//@include padding;
			margin-left: 30px;
			margin-right: 30px;
			display: flex;
			border-bottom: 1px solid $grey;
		}
		.headline{
			margin-top: 16px;
			text-transform: uppercase;
			@include min(sm) {
				@include flex(0,1,50%);
				padding-right: 5%;
			}
			@include min(lg) {
				padding-right: 10%;
			}
		}
		.ce_text{
			@include min(sm) {
				@include flex(0,1,50%);
			}
		}
		.button-block{
			.button{
				background: none;
				border: 1px solid $grey;
				color: $magenta-light;
				letter-spacing: 2.8px;
				padding: 8px 120px;
			    margin-top: 50px;
			    @include transition(color, border, $speed ease-in);
			    &:hover{
					border: 1px solid $magenta-light;
					color: $black;
			    }
			}
		}

	}

}


.ce_rsce_cc_12-01_clients {
	width: 100%;
	margin-top: 40px;
	margin-bottom: 40px;
	@include min(lg) {
		margin-right: 5px;
		margin-left: 5px;
	}
	.container-custom{
		.row{
			.teaser-block{
				@include flex(0,1,100%);
				.image-block{
					float: left;
					width: 100%;
					margin-top: 10px;
					margin-bottom: 10px;

					@include min(sm) {
						margin-top: 10px;
						margin-bottom: 10px;
						width: calc(50% - 5px);
						margin-right: 10px;
						margin-left: 10px;
						&:nth-child(2n){
							margin-right: 0px;
						}
						&:nth-child(n + 1){
							margin-left: 0px;
						}
					}
					@include min(md) {
						margin-top: 5px;
						margin-bottom: 5px;
						width: calc(25% - 15px);
						&:nth-child(n){
							margin-right: 10px;
							margin-left: 10px;
						}
						&:nth-child(4n){
							margin-right: 0px;
						}
						&:nth-child(4n + 1){
							margin-left: 0px;
						}
					}
					@include min(lg) {
						width: calc(20% - 16px);
						&:nth-child(n){
							margin-right: 10px;
							margin-left: 10px;
						}
						&:nth-child(5n){
							margin-right: 0px;
						}
						&:nth-child(5n + 1){
							margin-left: 0px;
						}
					}

					img{
						filter: grayscale(100%);
				    	@include transition(filter $speed ease-in);
						&:hover{
							filter: grayscale(0%);
						}
					}
					/*
					.image-holder{
						min-height: 480px;
						background-position: 50% 50%;
						background-repeat: no-repeat;
						background-size: contain;

						@include min(sm) {
							min-height: 480px;
						}
						@include min(md) {
							min-height: 360px;
						}
						@include min(lg) {
							min-height: 200px;
						}
					}
					*/
				}
			}
		}
	}
}







/* CUSTOMERS */
.ce_rsce_pp_01-01_hero_teaser_customers {
	padding: 0;
	width: 100%;
	.image-holder {
		height: calc(100vh - 200px);
		@include min(sm) {
			height: calc(100vh - 140px);
		}
		@include min(lg) {
			height: calc(100vh - 140px);
		}
		.container-custom {
			.row{
				@include min(sm) {
					@include padding;
				}
				.content-holder {
					text-transform: uppercase;
					width: 100%;
					@include min(sm) {
					}
					@include min(lg) {
					}
					@include min(xl) {
					}
					.headline {
						color: $white;
						text-align: center;
						@include font-size(2.2);
						letter-spacing: 8.8px;
			    		font-variation-settings: $extra-bold;
					}
					.ce_text{
						margin-top: 50%;
						@include min(sm) {
							margin-top: 25%;
						}
						@include min(lg) {
							margin-top: 20%;
						}

						@media (max-height: 850px) {
							margin-top: 15%;
						}
						@media (max-height: 800px) {
							margin-top: 10%;
						}
						@media (max-height: 750px) {
							margin-top: 5%;
						}
						@media (max-height: 700px) {
							margin-top: 0%;
						}
						.subheadline {
							color: $white;
							font-variation-settings: $regular;
							@include font-size(1.6);
							letter-spacing: 8.8px;
				    		font-variation-settings: $extra-bold;
							@include min(sm) {
								@include font-size(1.8);
							}
							@include min(md) {
								@include font-size(2.0);
							}
							@include min(lg) {
								@include font-size(2.2);
							}

						}

						h1, h2, h3, h4, h5, h6{
							&:not(.subheadline){
								//font-family: 'Roboto Slab';
				    			font-variation-settings: $extra-bold;
								letter-spacing: 8.8px;
								@include font-size(2.5);
								line-height: 1;
								color: $white;
								@include min(sm) {
									@include font-size(4.5);
									margin-top: 0px;
								}
								@include min(md) {
									@include font-size(8);
								}
								@include min(lg) {
									@include font-size(10);
								}

							}

						}
					}
					
				}
			}
		}
	}
}

/* VID HERO TEASER*/
.ce_rsce_pp_01-02_hero_teaser_customers_vid {
	padding: 0;
	width: 100%;

	height: calc(100vh - 200px);
	@include min(sm) {
		height: calc(100vh - 140px);
	}
	@include min(lg) {
		height: calc(100vh - 140px);
	}

	.video-container{
			overflow: hidden;
			@include min(sm) {
				position: absolute;
				height: calc(100vh - 140px);
				width: calc(100% + 15px);
			}
		video{
			@include min(sm) {
				//height: calc(100vh - 140px);
				position: absolute;
				min-width: 100%;
				left: 50%;
				top: 50%;
				min-height: 100%;
				-webkit-transform: translate(-50%, -50%);
				-moz-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
			}
		}
	}


	.image-holder {
		height: calc(100vh - 200px);
		@include min(sm) {
			position: absolute;
			height: calc(100vh - 140px);
			width: 100%;
		}
		.container-custom {
			.row{
				@include min(sm) {
					@include padding;
				}
				.content-holder {
					text-transform: uppercase;
					width: 100%;
					@include min(sm) {
					}
					@include min(lg) {
					}
					@include min(xl) {
					}
					.headline {
						color: $white;
						text-align: left;
						@include font-size(2.2);
						letter-spacing: 8.8px;
			    		font-variation-settings: $extra-bold;
						@include min(sm) {
							text-align: center;
						}
					}
					.ce_text{
						margin-top: 50%;
						@include min(sm) {
							margin-top: 25%;
						}
						@include min(lg) {
							margin-top: 20%;
						}

						@media (max-height: 850px) {
							margin-top: 15%;
						}
						@media (max-height: 800px) {
							margin-top: 10%;
						}
						@media (max-height: 750px) {
							margin-top: 5%;
						}
						@media (max-height: 700px) {
							margin-top: 0%;
						}
						.subheadline {
							color: $white;
							font-variation-settings: $regular;
							@include font-size(1.6);
							letter-spacing: 8.8px;
				    		font-variation-settings: $extra-bold;
							@include min(sm) {
								@include font-size(1.8);
							}
							@include min(md) {
								@include font-size(2.0);
							}
							@include min(lg) {
								@include font-size(2.2);
							}

						}

						h1, h2, h3, h4, h5, h6{
							&:not(.subheadline){
								//font-family: 'Roboto Slab';
				    			font-variation-settings: $extra-bold;
								letter-spacing: 8.8px;
								@include font-size(2.5);
								line-height: 1;
								color: $white;
								@include min(sm) {
									@include font-size(4.5);
									margin-top: 0px;
								}
								@include min(md) {
									@include font-size(8);
								}
								@include min(lg) {
									@include font-size(10);
								}

							}

						}
					}
					
				}
			}
		}
	}
}


/* VID inGRID*/
.ce_rsce_pp_11-01_customers_vid_inGrid {
	&.full_width {
		padding: 0;
		.video-container {
			.directVid {
				position: relative;
			    display: block;
			    margin: 0 auto;
				width: 100vw;
			}
		}
	}
	&.in_grid{
		padding: 0;
		.video-container{
			.directVid{
				position: relative;
			    display: block;
			    margin: 0 auto;
			    @include min(sm) {
			        width: 100%;
			        max-width: 100%;
			    }
			    @include min(md) {
			        max-width: 930px;
			    }
			    @include min(lg) {
			        max-width: 980px;
			    }
			    @include min(xl) {
			        max-width: 1180px;
			    }
			    @include min(xxl) {
			        max-width: 1380px;
			    }
			    @include min(max) {
			        max-width: 1380px;
			    }
			}
		}
	}
}








.ce_rsce_pp_02-01_reader_customers{
	@media (max-width: 767px) {
		background: none !important;
	}
	.teaser-block{
		position: relative;
	    background: $white;
		padding-bottom: 20px;
		margin-left: 10px;
		margin-right: 10px;
		@include min(sm) {
			padding: 80px 50px;
			top: -50px;
		}
		@include min(md) {
			top: -100px;
		}
		.inside{
			@include min(sm) {
				@include padding;
			}
			@include min(md) {
				display: flex;
			}

			.headline{
				text-transform: uppercase;
				@include min(sm) {
    				margin-top: 0;
				}
				@include min(md) {
					@include font-size(2.6);
					@include flex(0,1,50%);
					margin-right: 2%;
					padding-right: 2%;
    				border-right: 1px solid $grey;
    				margin-top: 0;
    				margin-bottom: 0;
    				padding-top: 20px;
    				padding-bottom: 60px;


				}
				@include min(xl) {
					padding-right: 10%;
			        @include font-size(4.0);
				}
			}


			.ce_text{
				@include min(md) {
					@include flex(0,1,50%);
				}
			}
			.button-block{
				.button{
					background: none;
					border: 1px solid $grey;
					color: $magenta-light;
					letter-spacing: 2.8px;
					padding: 8px 120px;
				    margin-top: 50px;
				    @include transition(color, border, $speed ease-in);
				    &:hover{
						border: 1px solid $magenta-light;
						color: $black;
				    }
				}
			}


		}
	}

}

.ce_rsce_pp_03-01_reader_customers_doubleText{
	.teaser-block{
		position: relative;
	    background: $white;
		padding-bottom: 20px;
		@include min(sm) {
			padding: 80px 0px;
			margin-left: 10px;
			margin-right: 10px;
		}
		@include min(lg) {
			margin-left: 15px;
			margin-right: 15px;
		}
		.inside{
			@include min(sm) {
				@include padding;
			}
			@include min(md) {
				display: flex;
			}
			.ce_text{
				@include min(md) {
					@include flex(0,1,50%);
					padding: 0px 60px;
					&:nth-child(1){
						border-right: 1px solid #dbdbdb;
					}
				}
			}


		}
	}

}





/* Chaos Pic elemets */

.ce_rsce_cc_19-01_chaos_Pic{
	//float: left;
	position: relative;
	&.in_grid{
		@media (max-width: 767px) {
			margin-top: 0 !important;
		}
		.container-custom{
			@media (max-width: 767px) {
				padding: 0;
			}
			.row{
				@media (max-width: 767px) {
					margin: 0;
				}
				.teaser-block{
					.image-block{
						img{
							@media (max-width: 767px) {
								height: 100% !important;
								width: 100% !important;
							}
						}
					}
				}
			}
		}
	}
	&.full_width{
		@media (max-width: 767px) {
			margin-top: 0 !important;
		}
		padding: 0;
		.container-custom{
			max-width: 100%;
			padding: 0;
			.row{
				margin: 0;
				.teaser-block {
					@include min(sm) {
						padding: 0;
					}
					.image-block{
						img{
							@media (max-width: 767px) {
								height: 100% !important;
								width: 100% !important;
							}
						}
					}
				}
			}
		}
	}
	.container-custom{
		.row{
			.teaser-block {
				@include flex(0,1,100%);
				@include min(sm) {
					@include padding;
				}
				.image-block{
					height: 100%;
					//display: flex;
					//border: 1px solid black;
					img{
						height: 100%;
						@include min(sm) {
							position: relative;
						}
					}
				}
			}
		}
	}
}


/* Text elements cases */
.ce_rsce_pp_03-02_text_customers {
	&.in_grid{
		.container-custom{
			.row{
				.teaser-block {
					margin-right: 10px;
					margin-left: 10px;
				}
			}
		}
	}
	&.full_width{
		padding: 0;
		.container-custom{
			max-width: 100%;
			.row{
				.teaser-block {
					.ce_text {
						p {
						    @include font-size(1.6);
						    font-variation-settings: "wght" 600;
						    @include min(sm) {
						        @include font-size(1.8);
						    }
						    @include min(md) {
						        @include font-size(1.8);
						    }
						    @include min(lg) {
						        @include font-size(2.0);
						    }
						    @include min(xl) {
						        @include font-size(2.2);
						    }

						}
					}
				}
			}
		}
	}
	.container-custom{
		.row{
			.teaser-block {
				@include flex(0,1,100%);
				background: $white;
				padding: 50px 20px;
			    @include min(sm) {
					padding: 50px 60px;
					position: relative;
			    }
			    @include min(md) {
					padding: 50px 115px;
			    }
			    @include min(lg) {
					padding: 50px 100px;
			    }
			    @include min(xl) {
					padding: 50px 200px;
			    }
				.teaser-padding {
					@media (max-width: 767px) {
						padding-bottom: 0 !important;
					}
					.ce_text {
						@media (max-width: 767px) {
							padding-bottom: 0 !important;
						}
						p {
						    @include font-size(1.6);
						    font-variation-settings: "wght" 600;
							@media (max-width: 767px) {
								padding-bottom: 0 !important;
							}
						    @include min(sm) {
						        @include font-size(1.8);
						    }
						    @include min(md) {
						        @include font-size(2.2);
						    }
						    @include min(lg) {
						        @include font-size(2.4);
						    }
						    @include min(xxl) {
						        @include font-size(3.0);
						    }

						}
					}
					.icon{
						display: none;
						@include min(sm) {
							display: block;
						}
						img{
							display: block;
							width: 100%;
						}
					}


				}

			}
		}
	}
}








/* Pic elemets */
.ce_rsce_pp_04-01_onePic {
	//float: left;
	&.in_grid{
		.container-custom{
			@media (max-width: 767px) {
				padding: 0;
			}
			.row{
				@media (max-width: 767px) {
					margin: 0;
				}
				.teaser-block {
					margin-right: 10px;
					margin-left: 10px;
				}
			}
		}
	}
	&.full_width{
		padding: 0;
		.container-custom{
			max-width: 100%;
			padding: 0;
			.row{
				margin: 0;
				.teaser-block {
					@include min(sm) {
						padding: 0;
					}
				}
			}
		}
	}
	.container-custom{
		.row{
			.teaser-block {
				@include flex(0,1,100%);
				@include min(sm) {
					//@include padding;
				}
				.image-block{
					display: flex;
					position: relative;
					img{
						@include min(sm) {
							position: relative;
						}
					}
					.headline{
						//text-transform: uppercase;
						z-index: 999;
						position: absolute;
						text-align: center;
						width: 100%;
						height: 100%;
						margin: auto;
						color: $white;
						font-variation-settings: $light;
						@include font-size(2.2);
						@include min(sm) {
							@include font-size(4.0);
							padding: 20px
						}
						@include min(md) {
							@include font-size(6.0);
						}
						@include min(lg) {
							//@include font-size(8.0);
							@include font-size(7.8);
						}
						span{
							@media only screen and (max-width: 992px) {
								justify-content: center !important;
							}
							height: 100%;
							align-items: center;
						    justify-content: center;
						    display: flex;
						}
					}
					.icon{
						display: none;
						z-index: 999;
						position: absolute;
						text-align: center;
						width: 100%;
						height: 100%;
						margin: auto;
						@include min(sm) {
							display: block;
							padding: 20px
						}
						img{
							display: block;
							position: absolute;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

.ce_rsce_pp_05-01_twoPic{
	//float: left;
	&.in_grid{
		.container-custom{
			@media (max-width: 767px) {
				padding: 0;
			}
			.row{
				@media (max-width: 767px) {
					margin: 0;
				}
				.teaser-block{
					width: 100%;
					margin-right: 10px;
					margin-left: 10px;
				}
			}
		}
	}
	&.full_width{
		padding: 0;
		.container-custom{
			max-width: 100%;
			padding: 0;
			.row{
				margin: 0;
				.teaser-block {
					width: 100%;
					@include min(sm) {
						padding: 0;
					}
				}
			}
		}
	}
	.container-custom{
		.row{
			.teaser-block {
				@include min(sm) {
					display: flex;
					//@include padding;
				}
				.image-block{
					display: flex;
					@include flex(0,1,100%);
					//border: 1px solid red;
					@include min(sm) {
						@include flex(0,1,50%);
					}
				}
			}
		}
	}
}

.ce_rsce_pp_06-01_threePic{
	//float: left;
	&.in_grid{
		.container-custom{
			@media (max-width: 767px) {
				padding: 0;
			}
			.row{
				@media (max-width: 767px) {
					margin: 0;
				}
				.teaser-block{
					width: 100%;
					margin-right: 10px;
					margin-left: 10px;
				}
			}
		}
	}
	&.full_width{
		padding: 0;
		.container-custom{
			max-width: 100%;
			padding: 0;
			.row{
				margin: 0;
				.teaser-block {
					@include min(sm) {
						padding: 0;
					}
				}
			}
		}
	}
	.container-custom{
		.row{
			.teaser-block {
				@include min(sm) {
					display: flex;
					//@include padding;
				}
				.image-block{
					display: flex;
					//border: 1px solid black;
					@include flex(0,1,100%);

					@include min(sm) {
						@include flex(0,1,33.333%);
					}
				}
			}
		}
	}
}


.ce_rsce_pp_07-01_onePic_Overlay{
	float: left;
	&.in_grid{
		.container-custom{
			@media (max-width: 767px) {
				padding: 0;
			}
			.row{
				@media (max-width: 767px) {
					margin: 0;
				}
			}
		}
	}
	&.full_width{
		padding: 0;
		.container-custom{
			max-width: 100%;
			padding: 0;
			.row{
				margin: 0;
				.teaser-block {
					@include min(sm) {
						padding: 0;
					}
				}
			}
		}
	}
	.container-custom{
		.row{
			.teaser-block {
    			position: relative;
    			width: 100%;
				@include min(sm) {
					@include padding;
				}
				.image-block{
					img{
						width: 100%;
						//z-index: 10;
					}
					img:nth-child(2){
						position: absolute;
						//z-index: 1;
						left: 15px;
						width: calc(100% - 30px);
					}
				}
			}
		}
	}
}









/* KARRIERE */
.career{
	/* hero element */
	.ce_rsce_cc_01-01_hero_teaser {
		z-index: 999;
		.content-holder{
			position: relative;
		}
		.image-holder{
			height: auto;
		}
		.subheadline{
			position: relative;
			width: 100%;
			text-transform: uppercase;
			animation-name: none;
			h1, h2, h3, h4, h5, h6, p, li, a{
				color: $white;
			}

		}
	}
}

.ce_rsce_cc_23-01_career_teaser_boxes_popUp {
	padding-left: 10px;
	padding-right: 10px;
	.container-custom{
		.row{
			.teaser-holder{
				margin-left: 10px;
				margin-right: 10px;
				@include min(sm) {
					//@include padding;
				}
				.headline{
					width: 100%;
					text-align: center;
					color: $white;
				}
				&:last-child{
					padding-bottom: 20px;
					@include min(sm) {
						padding-bottom: 40px;
					}
					@include min(md) {
						padding-bottom: 60px;
					}
					@include min(lg) {
						padding-bottom: 80px;
					}
				}
				.teaser-group{
					.teaser-block{
						background-color: $white;
						padding: 20px 40px;
						margin-bottom: 40px;
						@include min(sm) {
							padding: 20px 40px;
						}
						@include min(md) {
							padding: 40px 60px;
							margin-bottom: 60px;
						}
						@include min(lg) {
							padding: 50px 80px;
							margin-bottom: 80px;
						}
						/* Beschreibungstext */
						&.description {
							.teaser-block-text-left {
								@include min(md) {
									width: 50%;
								}
								h2 {
									margin-bottom: 0;
								}
								h4 {
								}

							}
							.teaser-block-text-right {
								@include min(xl) {
									width: 50%;
									margin-left: 50%;
								}
								@include min(xl) {
									padding: 0 40px;
								}

							}
						}
						/* BOX 1 - TEASER TEXT */
						.teaser-block-teaser{
							.subheadline{
								color: $magenta-light;
							}
						}
	
						/* BOX 2 - ICON CLICK-ME */
						.teaser-block-icon{
							margin-top: 0px;
							.icon-holder{
								margin: 0 auto;
								width: 40px;
								height: 40px;
								padding: 2px;
								.image-holder{
									cursor: pointer;
									height: 100%;
									margin-top: 38px;
									background-image: url('../../images/icons/plus-white.svg');
									background-position: 50% 50%;
									background-repeat: no-repeat;
									background-size: cover;
									background-color: $magenta-light;
									@include transition(all $speed ease-in);
									@include min(sm) {
										margin-top: 38px;
									}
									@include min(md) {
										margin-top: 60px;
									}
									@include min(lg) {
										margin-top: 70px;
									}
									&:hover{
										background-color: $black;
									}
								}
							}
						}
						/*
                        &.show-description{
							.teaser-block-icon{
								.icon-holder{
									.image-holder{
										margin-top: 0px;
										background-image: url('../../images/icons/minus-white.svg');
									}
								}
							}
						}
						*/

						/* BOX 3 - DETAIL DESCRIPTION */
						.teaser-block-description{
							.teaser-description{
								h1, h2, h3, h4, h5, h6{
									color: $magenta-light;
								}
								display: none;
							}
						}
					}
				}
			}
		}
	}
}








































body.safari {
	.ce_rsce_three_boxes .teaser-block {
		@include min(sm) {
			margin: 0 -1px;
		}
	}
	section{
		.container-custom{
			.row{
				img{
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}















