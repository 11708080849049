/* -----------------------------------
FOOTER
----------------------------------- */
#footer {
    position: relative;
    background: $magenta-gradient;
    padding-top: 20px;
    padding-bottom: 20px;
    @include min(md) {
        height: 60px;
    }
    .inside {
        .container {
            @include min(md) {
                padding: 0;
            }
            .row {
                position: relative;
                z-index: 10;
                .footer {
                    width: 100%;
                    text-transform: uppercase;
                    letter-spacing: 2.4px;
                    @include font-size(1.4);
                    font-variation-settings: $regular;
                    .footer-left{
                        margin-bottom: 15px;
                        width: 100%;
                        div {
                            p{
                                color: $white;
                                margin-top: 0px;
                                margin-bottom: 0px;
                                text-align: center;
                            }
                        }
                        @include min(md) {
                            margin-bottom: 0px;
                            width: auto;
                            float: left;
                            div{
                                p{
                                    text-align: left;
                                }
                            }
                        }
                        @include min(xxl) {
                            width: 50%;
                        }
                    }


                    .footer-right{
                        width: 100%;
                        float: right;
                        div{
                            float: none;
                            margin-top: 0px;
                            margin-bottom: 0px;
                            padding-left: 15px;
                            padding-right: 15px;
                            text-align: center;
                            a {
                                color: $white;
                                font-variation-settings: $regular;
                                text-decoration: none;
                                @include transition(color $speed ease-in);
                                &:hover {
                                    color: $hover-black;
                                }
                            }
                        }
                        @include min(md) {
                            width: auto;
                            div{
                                float: right;
                                a {
                                    text-align: left;
                                }
                            }
                        }
                        @include min(xxl) {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}
