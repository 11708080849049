
/* Background Specials Gradients */
.magenta-bg-light-dark{
	@include min(sm) {
    	background: $magenta-gradient-light-dark;
    }
}
.magenta-bg-dark-light{
	@include min(sm) {
    	background: $magenta-gradient-dark-light;
    }
}

// Kontakt
.magenta-bg-light-dark-half01{
	@include min(sm) {
    	background: linear-gradient(180deg, $magenta-light, rgb(196, 0, 61));
    }
}
.magenta-bg-light-dark-half02{
	@include min(sm) {
    	background: linear-gradient(180deg, rgb(196, 0, 61), $magenta-gradient-dark);
    }
}

/* Karriere */
.strip-bg{
	background-image: linear-gradient(45deg, #be233a 16.67%, #ba1730 16.67%, #ba1730 50%, #be233a 50%, #be233a 66.67%, #ba1730 66.67%, #ba1730 100%);
	background-size: 42.43px 42.43px;
	.ce_rsce_hero_teaser{
		z-index: 999;
	}
}
.strip-bg-dark-hero {
	background: linear-gradient(180deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,.3) 100%);
}
.strip-bg-dark-jobs {
	background: linear-gradient(180deg, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 12%, rgba(0,0,0,0) 100%);
}