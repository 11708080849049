.mod_latest_main {
    @include padding;
    margin-top: 80px;
	@include min(md) {
		display: flex;
	}
	.layout_latest {
    	display: flex;
		&.even{
			//display: flex;
			@include flex(0,1,100%);
		    background: #ff0045;

			@include min(md) {
				margin-right: 10px;
				@include flex(0,1,calc(66.667% - 10px));
			}


			.content-holder{
				padding: 52px 50px 52px 60px;
				background: $magenta-light;
				width: 100%;

				@include min(sm) {
					width: 50%;
				}
				h2 a, h3 a, h4 a{
					color: $black;
					&:hover{
						color: $hover;
					}
				}
				.ce_text{
					color: $white;
				}
				.info{
					color: $white;
				}
				.more a{
					color: $black;
					&:hover{
						color: $hover;
					}
				}

			}


			.image-block{
				display: none;
				
				@include min(sm) {
					width: 50%;
					display: block;
				}
				@include min(lg) {
					@include flex(0,1,50%);
				}
				.image-holder{
					height: 100%;
					background-position: 50% 50%;
					background-repeat: no-repeat;
					background-size: cover;
				}
			}





		}


		&.odd{
			@include flex(0,1,100%);
			border: 2px solid $grey;
			@include min(md) {
				@include flex(0,1,calc(33.333% - 10px));
				margin-left: 10px;
			}

			.content-holder{
				padding: 52px 50px 52px 60px;
				width: 100%;
			}
			.image-block{
				display: none;
			}
			h2 a, h3 a, h4 a{
				color: $black;
				&:hover{
					color: $magenta-light;
				}
			}







		
		}

	}

}







