/* SLIDER */
.slider-header{
    width: 100% !important;
    padding: 0 !important;
    @include min(sm) {
        height: calc(100vh - 140px) !important;
    }
    .rsts-view {
        .rsts-crop {
            @include min(sm) {
                //height: calc(100vh - 140px) !important;
                width: 100vw !important;
            }
            .rsts-slides {
                width: 100vw !important;
                .rsts-slide {
                    @include min(sm) {
                        //height: calc(100vh - 140px) !important;
                        width: 100vw !important;
                    }
                    div {
                        .ce_rsce_cc_01-02_hero_slider {
                            .content-holder {
                                opacity: 0;
                                @include transition(opacity, border, $speed ease-in);

                            }
                        }
                    }
                    &.rsts-active {
                        z-index: 999;
                        div {
                            .ce_rsce_cc_01-02_hero_slider {
                                .content-holder {
                                    opacity: 1;
                                    @include transition(opacity, border, $speed ease-in);
                                }
                            }
                        }
                    }
                    img{
                        object-fit: cover;
                        @include min(sm) {
                            height: calc(100vh - 140px) !important;;
                            width: 100% !important;
                            margin: 0 !important;
                        }
                    }

                }
            }
        }
    }


    /* delete me */
    .slider-padding-right-40 + .rsts-caption{
        @include min(sm) {
            @include font-size(4);
            padding-left: 24%;
            padding-right: 40%;
        }
        @include min(md) {
            padding-left: 30%;
            padding-right: 40%;
        }
        @include min(lg) {
            padding-left: 8%;
            padding-right: 8%;
        }
        @include min(xxl) {
            @include font-size(10.6);
            padding-left: 18%;
            padding-right: 40%;
        }
        @include min(max) {
            padding-left: 22%;
            padding-right: 40%;
        }
    }
    .slider-padding-right-5 + .rsts-caption{
        @include min(sm) {
            padding-right: 20%;
        }
        @include min(md) {
            padding-right: 20%;
        }
        @include min(lg) {
            padding-right: 8%;
        }
        @include min(xxl) {
            padding-right: 5%;
        }
        @include min(max) {
            padding-right: 5%;
        }
    }
    /* delete me end */


    .rsts-caption{
        font-family: 'Roboto Slab';
        font-variation-settings: $regular;
        width: 100%;
        margin: 0 auto;
        //text-align: center;
        text-align: left;
        background-color: transparent;
        transition: none;
        left: 0px;
        top: calc(50% - 12px);
        line-height: 1;
        padding-left: 20%;
        padding-right: 20%;
        text-transform: uppercase;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        top: 0;

        /* delete me */
        @include min(sm) {
            @include font-size(4);
            //top: calc(50% - 45px);
            padding-left: 24%;
            padding-right: 24%;
        }
        @include min(md) {
            padding-left: 30%;
            padding-right: 30%;
        }
        @include min(lg) {
            //@include font-size(10.6);
            //top: calc(50% - 111px);
            //top: calc(50% - 45px);
            padding-left: 8%;
            padding-right: 8%;
        }
        @include min(xxl) {
            @include font-size(10.6);
            padding-left: 18%;
            padding-right: 18%;
        }
        @include min(max) {
            padding-left: 22%;
            padding-right: 22%;
        }
        /* delete me end */

    }
    
    .rsts-nav-thumbs{
        position: absolute;
        right: 0;
        left: 0;
        display: none;
        bottom: 0;

        @include min(sm) {
            display: block;
        }

        .rsts-thumbs-main{
            //@include padding;
            width: 100%;
            margin: 0 auto;
            @include min(sm) {
                max-width: 720px;
            }
            @include min(md) {
                max-width: 960px;
            }
            @include min(lg) {
                max-width: 1000px;
            }
            @include min(xl) {
                max-width: 1200px;
            }
            

            @include min(xxl) {
                max-width: 1400px;
            }
            .rsts-thumbs-crop{
                //height: auto !important;
                height: 100px !important;
            }



            .rsts-thumbs-slides{
                transform: none !important;
                //width: 100%;
                position: relative;
                :first-child:nth-last-child(1){
                    width: 100% !important;
                }
                :first-child:nth-last-child(2):not(h3),
                :first-child:nth-last-child(2) ~ .rsts-thumbs-slide{
                    width: 50% !important;
                }
                :first-child:nth-last-child(3):not(h3),
                :first-child:nth-last-child(3) ~ .rsts-thumbs-slide{
                    width: 33.333% !important;
                }
                :first-child:nth-last-child(4):not(h3),
                :first-child:nth-last-child(4) ~ .rsts-thumbs-slide{
                    width: 25% !important;
                }
                :first-child:nth-last-child(5):not(h3),
                :first-child:nth-last-child(5) ~ .rsts-thumbs-slide{
                    width: 20% !important;
                }
                :first-child:nth-last-child(6):not(h3),
                :first-child:nth-last-child(6) ~ .rsts-thumbs-slide{
                    width: 16.666% !important;
                }
                :first-child:nth-last-child(7):not(h3),
                :first-child:nth-last-child(7) ~ .rsts-thumbs-slide{
                    width: 14.285% !important;
                }
                :first-child:nth-last-child(8):not(h3),
                :first-child:nth-last-child(8) ~ .rsts-thumbs-slide{
                    width: 12.5% !important;
                }
                :first-child:nth-last-child(9):not(h3),
                :first-child:nth-last-child(9) ~ .rsts-thumbs-slide{
                    width: 11.111% !important;
                }
                :first-child:nth-last-child(10):not(h3),
                :first-child:nth-last-child(10) ~ .rsts-thumbs-slide{
                    width: 10% !important;
                }



                .rsts-thumbs-slide{
                    //height: auto !important;
                    //@include padding;
                    padding: 0 10px;
                    float: left;
                    transform: none !important;
                    position: relative;
                    //border: 1px solid black;
                    height: 100px !important;
                    //margin-bottom: 54px;
                    opacity: .3;
                    @include transition(opacity $speed-slider ease-in);
                    &.rsts-thumbs-active-thumb {
                        opacity: 1;
                    }
                    a{
                        img{
                            display: none !important;
                        }
                        div{
                            // position: absolute;
                            // bottom: 0;
                            img{
                                display: none !important;
                            }
                            h3{
                                text-transform: uppercase;
                                @include font-size(1.6);
                                font-variation-settings: $bold;
                                font-weight: 600;
                                color: $white;
                                text-align: left;
                            }
                            p{
                                @include font-size(1.2);
                                color: $white;
                                text-align: left;
                            }
                        }
                        
                    }
                    &:before {
                        content: '';
                        display: block;
                        width: 0;
                        height: 2px;
                        background: $white;
                        opacity: 0.3;
                        position: absolute;
                        left: 10px;
                        top: 0px;
                        width: calc(100% - 20px);
                        //@include transition(width $speed-slider ease-in);
                    }
                    &:after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 2px;
                        //background: $magenta-light;
                        background: $white;
                        position: absolute;
                        top: 0px;
                        left: 10px;
                        @include transition(width $speed-slider ease-in);
                    }
                    /*
                    &:hover {
                        &:after {
                            @include transition(width $speed ease-in);
                            width: 100%;
                        }
                    }
                    */

                    &.rsts-thumbs-active-thumb{
                        a{
                            cursor: default;
                        }
                        &:after {
                            //width: 100%;
                            width: calc(100% - 20px);
                        }

                    }
                }
            }
        }
    }
}




.rsts-prev, .rsts-next, .rsts-skin-default .rsts-prev, .rsts-skin-default .rsts-next{
    position: absolute;
    top: calc(50% - 60px);
    width: 120px;
    height: 120px;
    border: none;
    font-size: 0;
    z-index: 999;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    @include transition(background-image $speed ease-in);
    &:hover{
        background-color: unset;
    }
}

.rsts-prev, .rsts-skin-default .rsts-prev{
    left: 20px;
    background-image: url("../../images/icons/chevron-left-slider-cases.svg");
    &:hover{
        background-image: url("../../images/icons/chevron-left-slider-cases-magenta.svg");
    }
}
.rsts-next, .rsts-skin-default .rsts-next{
    right: 20px;
    left: auto;
    background-image: url("../../images/icons/chevron-right-slider-cases.svg");
    &:hover{
        background-image: url("../../images/icons/chevron-right-slider-cases-magenta.svg");
    }
}






/* PDF-SLIDER */
.ce_rsce_pp_10-01_pdf_slider{

    @include min(sm) {
        padding-right: 0px;
        padding-right: 0px;
    }

    //float: left;
    .container-custom{
        .row{
            margin-right: 15px;
            margin-left: 15px;
            margin-top: 20px;
            @include min(sm) {
                margin-top: 40px;
            }

            #flipbook{
                height: 100%;
                width: 100%;
                .page{
                    height: 100%;
                    width: 100%;
                }
                .hard{
                    background: rgba(255,255,255,0.8);
                    color: $black;
                    //line-height:600px;
                    text-align:center;
                    box-shadow: inset 0 0 30px rgba(125,125,125,0.8);
                    font-variation-settings:bold;
                    h5{
                        margin: 0;
                    }
                }

                .page-wrapper{
                    .image-block{
                        .imgHolder{
                            width: 100%;
                            height: 100%;

                            img{
                                //width: 100%;
                                //height: 100%;
                            }
                        }
                    }

                }

            }
            /* Counter */
            .counter-block{
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;
                display: flex;
                @include min(sm) {
                    @include padding;
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
                .center-block{
                    margin: 0 auto;

                    .counter-element{
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        border: 1px solid $magenta-light;
                        border-radius: 50%;
                        margin-left: 10px;
                        margin-right: 10px;
                        @include transition(all, $speed ease-in);
                        &:hover{
                            opacity: .3;
                            cursor: pointer;
                            background-color: $magenta-light;
                        }
                    }
                }

            }
        }
    }
}


/* ROCK SOLID SLIDER REFERENZEN */
.mod_rocksolid_slider:not(.slider-header) {
    &.grey-bg .rsts-slide{
        background: $grey !important;
    }
    .rsts-view {
        .rsts-crop {
            width: 100vw !important;
            .rsts-slides {
                width: 100%;
                height: auto !important;
                .rsts-slide {
                    width: 100% !important;
                    height: auto !important;
                    img {
                        width: 100% !important;
                        height: auto !important;
                    }
                }
            }
        }
    }
    .rsts-prev, .rsts-skin-default .rsts-prev{
        left: 20px;
        background-image: url("../../images/icons/chevron-left-slider-cases.svg");
        &:hover{
            background-image: url("../../images/icons/chevron-left-slider-cases-magenta.svg");
        }
    }
    .rsts-next, .rsts-skin-default .rsts-next{
        right: 20px;
        left: auto;
        background-image: url("../../images/icons/chevron-right-slider-cases.svg");
        &:hover{
            background-image: url("../../images/icons/chevron-right-slider-cases-magenta.svg");
        }
    }
    &.in_grid{
        .rsts-view{
            position: relative;
            margin: 0 auto;
            @include min(sm) {
                width: 100%;
                max-width: 100%;
            }
            @include min(md) {
                max-width: 950px;
            }
            @include min(lg) {
                max-width: 1000px;
            }
            @include min(xl) {
                max-width: 1200px;
            }
            @include min(xxl) {
                max-width: 1400px;
            }
            @include min(max) {
                max-width: 1400px;
            }
            .rsts-crop{
                width: inherit !important;
                .rsts-slides{
                    .rsts-slide{
                        div[data-rsts-type="image"]{
                            margin-left: 10px;
                            margin-right: 10px;
                        }
                        img{
                            padding: 0;
                            height: auto !important;
                            width: 100% !important;
                        }
                    }
                }
            }
            .rsts-next{
                right: -20px;
                left: auto;
            }
            .rsts-prev{
                left: -20px;
            }
        }
    }



    /* BULLETS PONTS */
    &.bullet-nav{
        .rsts-nav{
            position: relative;
            background: $grey;
            left: 0;
            bottom: 0;
            ul{
                text-align: center;
                margin: 0;
                padding: 0;

                .rsts-nav-prev, .rsts-nav-next {
                    display: none !important;
                }
                li{
                    display: inline;
                    a{
                        display: inline-block;
                        margin: 12px 8px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        text-indent: -9999px;
                        background-color: #555;
                        background-clip: padding-box;
                        box-shadow: inset 0 1px 2px -1px black, inset 0 0 2px rgba(0,0,0,1);
                        &.active{
                            background-color: rgba(0,0,0,0);
                        }
                    }
                }
            }
        }
    }
}



/* SLIDER SKIN TRANSPARENT */
.rsts-skin-transparent.rsts-main {
    position: relative;
    background-color: transparent;
    
    .rsts-slide {
      background-color: transparent;
    }
}
/* SLIDER SKIN GREY */
.rsts-skin-grey.rsts-main {
    position: relative;
    background-color: $grey;
    
    .rsts-slide {
      background-color: $grey;
    }
}
/* SLIDER SKIN Eltropa Yellow */
.rsts-skin-yellow.rsts-main {
    position: relative;
    background-color: #FEDD08;
    
    .rsts-slide {
      background-color: #FEDD08;
    }
}

/* SLIDER SKIN Tai black */
.rsts-skin-tai.rsts-main {
    position: relative;
    background-color: #1d2124;
    
    .rsts-slide {
      background-color: #1d2124;
    }
}


/* SLIDER SKIN Loxeo black */
.rsts-skin-loxeo.rsts-main {
    position: relative;
    background-color: #191d28;
    
    .rsts-slide {
      background-color: #191d28;
    }
}

/* SLIDER SKIN Loxeo black */
.rsts-skin-loxeo-top.rsts-main {
    position: relative;
    background-color: #191d28;
    .rsts-crop{

        .rsts-slide {
          background-color: #191d28;
        }
    }
}


/* SLIDER SKIN Handtmann */
.rsts-skin-handtmann.rsts-main {
    position: relative;
    background-color: #323232;
    
    .rsts-slide {
      background-color: #323232;
    }
}


/* SLIDER SKIN Eberspächer */
.rsts-skin-eberspaecher.rsts-main {
    position: relative;
    background-color: $grey;
    
    .rsts-slide {
      //background-color: none;
      z-index: 11;
    }
}


















