
/* -----------------------------------
TYPO
----------------------------------- */


/* FONTS */
@font-face{
    font-family: 'Heebo';
    font-display: swap;
    src: 
        local('Heebo'),
        local('Heebo-Regular'),
        url("../fonts/Heebo/Heebo-VariableFont_wght.ttf") format("truetype");
}
@font-face{
    font-family: 'Roboto Slab';
    font-display: swap;
    src: 
        local('Roboto Slab'),
        local('RobotoSlab-Regular'),
        url("../fonts/Roboto_Slab/RobotoSlab-VariableFont_wght.ttf") format("truetype");
}



//  $thin: 'wght' 100;
//  $extra-light: 'wght' 200;
$light: 'wght' 300;
$regular: 'wght' 400;
//  $medium: 'wght' 500;
$bold: 'wght' 600;
$semi-bold: 'wght' 700;
$extra-bold: 'wght' 800;
$mega-bold: 'wght' 900;

html {
    font-size: 62.5%;
}
body {
    font-family: 'Heebo';
    @include font-size(1.6);
    color: $text-color;
    font-variation-settings: $regular;
}

p, li {
    font-family: inherit;
    font-variation-settings: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    strong{
        font-variation-settings: $bold;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Heebo';
    color: $headline-color;
    line-height: 1;
    //text-transform: uppercase;
    letter-spacing: 0px;
    font-variation-settings: $bold;
}

h1 {
    @include font-size(4.0);
    text-transform: uppercase;

    @include min(sm) {
        @include font-size(5.0);
    }
    @include min(lg) {
        @include font-size(12.0);
    }
}

h2 {
    @include font-size(3.6);
    @include min(sm) {
        @include font-size(4.6);
    }
    @include min(lg) {
        @include font-size(7);
    }
}

h3 {
    @include font-size(2.2);
    @include min(sm) {
        @include font-size(2.6);
    }
    @include min(lg) {
        @include font-size(4.0);
    }
}

h4 {
    @include font-size(2.0);
    @include min(sm) {
        @include font-size(2.2);
    }
    @include min(lg) {
        @include font-size(2.4);
    }
}

h5, h6 {
    @include font-size(1.8);
    @include min(sm) {
        @include font-size(2);
    }
    @include min(lg) {
        @include font-size(2.2);
    }
}























