.mod_latest_customers, .mod_all_customers {
	width: 100%;
	@include min(md) {
		//@include padding;
		padding-left: 5px;
		padding-right: 5px;
	}
	@include min(xl) {
		margin-right: 5px;
		margin-left: 5px;
	}
	.layout_latest_customers{
		a:hover > .image-holder .text-holder h3{
			color: $magenta-light;
		}
		margin: 20px 10px;

		@include min(md) {
			float: left;
			//box-shadow: 0px 2px 13px rgba(0,0,0,.2);
			margin-top: 0px;
			margin-bottom: 20px;
			width: calc(50% - 10px);
			&:nth-child(odd){
				margin-left: 0px;
				margin-right: 10px;
			}
			&:nth-child(even){
				margin-left: 10px;
				margin-right: 0px;
			}
		}
		@include min(lg) {
			width: calc(50% - 15px);
			&:nth-child(odd){
				margin-left: 5px;
				margin-right: 10px;
			}
			&:nth-child(even){
				margin-left: 10px;
				margin-right: 5px;
			}
		}
		@include min(xl) {
			margin-top: 0px;
			margin-bottom: 46px;
			//margin-left: 5px;
			//margin-right: 5px;
			width: calc(33.333% - 17px);
			&:nth-child(3n + 1){
				margin-left: 0px;
				margin-right: 15px;
			}
			&:nth-child(3n + 2){
				margin-left: 10px;
				margin-right: 10px;
			}
			&:nth-child(3n + 3){
				margin-left: 15px;
				margin-right: 0px;
			}
		}

		.image-holder {
			.text-holder {
				min-height: 360px;
				padding-left: 30px;
				padding-right: 30px;
				//padding-top: 250px;
				padding-bottom: 30px;
				background: transparent linear-gradient(0deg, rgba(0,0,0, 1) 0%, rgba(0,0,0, .01) 40%, rgba(0,0,0,0) 100%) 0% 0% no-repeat padding-box;
				@include min(lg) {
					min-height: 500px;
					padding-left: 30px;
					padding-right: 30px;
					//padding-top: 250px;
					padding-bottom: 30px;
				}
				@include min(md) {
					min-height: 500px;
				}

				.ce_text{
					position: absolute;
					bottom: 30px;
					width: calc(100% - 30px);
					h3{
						margin-top: 5px;
						margin-bottom: 20px;
						color: $white;
						text-transform: uppercase;
						letter-spacing: 1.5px;
						font-variation-settings: 'wght' 900;
						&:hover{
							color: $hover;
						}
						@include min(md) {
							@include font-size(3.0);
						}
						&:hover{
							color: $magenta-light;
						}
					}
					p{
						font-variation-settings: 'wght' 800;
						margin: 0px;
						color: $white;
						text-transform: uppercase;
						letter-spacing: 1.4px;
						&:hover{
							//color: $hover;
						}
						@include min(md) {
							@include font-size(1.4);
						}
					}
					.logo-holder{    
						position: absolute;
						right: 30px;
    					bottom: 0;
					}
				}

			}
		}
	}
}
