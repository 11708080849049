/* ----------
NAVIGATION
---------- */
.burger-block {
    p {
        margin-bottom: 0;
        margin-top: 0;
        @include clearfix;
        display: block;
        @include min(sm) {
            float: left;
        }
    }
    .menu-text{
        display: none;
        @include min(sm) {
            display: block;
            padding-top: 5px;
            @include font-size(1.4);
            letter-spacing: 1.4px;
            font-variation-settings: 'wght' 800;
        }
    }
}



.sidebar-opener,
.sidebar-closer {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    font-size: 0;
    float: right;
    @include min(sm) {
        float: initial;
        margin: 0 auto;
    }
}

.sidebar-opener {
    span {
        display: none;
        font-size: 0;
    }
    &:before {
    	content: $icon-menu;
    }
    &:after {
    	content: $icon-x;
    	@include transformX(125%);
    }
    &.is-active {
        .bar {
            @include transformX(100%);
        }
        .close {
            @include transformX(0);
        }
	    &:before {
	    	@include transformX(-125%);
	    }
	    &:after {
	    	@include transformX(0);
	    }
    }
}

.sidebar-closer {
    margin-top: 15px;
    //width: 100%;
    position: fixed;
    right: 0px;
    margin-right: 25px;
    z-index: 1001;
    @include min(sm) {
        display: none; 
    }
    /*
    &:before {
        @include iconmoon;
        color: $white;
        @include font-size(2.4);
        position: absolute;
        top: 50%;
        margin: -12px 0 0 -12px;
        will-change: transform;
        @include transition(transform $speed ease-in);
    }
    &:before {
    	content: $icon-x;
    }
    */
}

.offcanvas-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    text-align: left;
    overflow: hidden;
    @include transformY(-100%);
    will-change: transform;
    @include transition(transform .8s);
    background: $menu-gradient;
    z-index: 9999;
    @include min(sm) {
    }
    @include min(lg) {
    }
    &.is-open {
        @include transformY(0);
    }
    .seperator{
        display: none;
        @include min(sm) {
            display: block;
            position: relative;
            width: 100%;
            border-top: 1px solid $grey;
            margin-top: -50px;
        }
    }


}



.nothing-holder{
    width: 100%;
    height: 100%;
    cursor: pointer;
}


.menu-holder {
    position: relative;
    width: 100% !important;
    padding: 0 25px;
    background: $white;
    height: 100%;
    overflow: scroll;
    @include min(sm) {
        padding: 0;
        height: auto;
        overflow: auto;
    }
    .header-logo{
        margin: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        display: none;
        @include min(sm) {
            display: block;
        }
        @include min(lg) {
            margin-left: 10px;
        }
        img{
            height: 15px;
        }
    }
    .burger-block{
        display: none;
        @include min(sm) {
            display: inherit;
        }
        p {
            margin-top: auto;
            margin-bottom: auto;
            padding-top: 0;
        }
    }
}




#nav-mobile {
    position: relative;
    width: 100%;
    padding-bottom: 20px;
        @include min(sm) {
            //border-bottom: 1px solid $magenta-light;
            padding-bottom: 60px;
        }

    & ul {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    & li {
        position: relative;
        & a, & strong {
            display: block;
            padding: 25px 28px 25px 0;
            line-height: 1;
            @include min(sm) {
                padding: 25px 28px 45px 0;
            }
        }
        & span {
            cursor: pointer;
        }
        &.trail, &.active {
            & > a, & > strong {
                color: $hover;
            }
        }
    }
    .level_1 {
        height: 100%;
        text-transform: uppercase;
        @include min(sm) {
            padding-left: 15px;
            padding-right: 15px;
        }
        @include min(lg) {
            padding-left: 25px;
            padding-right: 25px;
        }
        & > li {
            &.first{
                @include min(sm) {
                    display: none;
                }

            }
            @include min(lg) {
                width: 25%;
                float: left;
            }
            a, strong {
                @include font-size(1.8);
                color: $magenta-light;
                letter-spacing: 1.4px;
                font-variation-settings: $extra-bold;
            }
            a{
                padding: 25px 28px 25px 0;
                @include min(sm) {
                    padding: 25px 28px 45px 0;
                }
                &:hover{
                     color: $hover-black;
                }
            }
            strong {
                color: $hover-black;
            }
        }
    }
    .level_2 {
        .first{
            display: block;
        }
        //padding-left: 20px;
        @include min(sm) {
            padding-left: 0;
        }
        & > li {
        	& a, & strong {
                @include font-size(1.4);
                color: $text-color;
                padding: 5px 0px 5px 0;
                letter-spacing: 1.4px;
        	}
            a{
                &:hover{
                     color: $hover;
                }
            }
            strong {
                color: $hover-black;
            }

        }
    }
}
