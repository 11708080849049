.owl-carousel {
  position: relative;
  .owl-stage-outer {
    //border: 2px solid $grey;
  }
  .owl-stage{
    display: flex;
  }
  .owl-item {
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    // opacity: 0.5;
    //border: 2px solid $grey;
    display: flex;
    @include flex(0,1,100%);
    &.active {
      // opacity: 1;
    }
    img {
      margin: 0 auto;
    }
  }
  .owl-nav {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    @include min(sm) {
        // height: 58px;
    }
    @include min(lg) {
        width: $width-lg;
        left: 50%;
        @include transformX(-50%);
    }
    div {
      position: absolute;
      top: 0;
      font-size: 0;
      line-height: 1;
      border-radius: 3px;
      color: $white;
      width: 35px;
      height: 40px;
      margin-top: -20px;
      @include transition(color $speed ease-in);
      @include min(sm) {
          height: 80px;
          margin-top: -40px;
      }
      &:before {
        @include iconmoon;
        @include font-size(4);
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(-50%,-50%);
        @include min(sm) {
            @include font-size(8);
        }
      }
      &:hover {
          color: $hover;
      }
      &.owl-prev {
        left: 20px;
        @include min(sm) {
            // left: calc(6.33% - 10px);
            left: 25px;
        }
        @include min(lg) {
            left: 0;
        }
        &:before {
            // content: $icon-arrow-slider-left;
        }
      }
      &.owl-next {
        @include rotate(180);
        right: 20px;
        @include min(sm ) {
            // right: calc(6.33% - 10px);
            right: 25px;
        }
        @include min(lg) {
            right: 0;
        }
        &:before {
            // content: $icon-arrow-slider-left;
        }
      }
    }
  }
  & .owl-dots {
    position: relative;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 37px 0;
    -js-display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;


    & > button{
          width: 12px;
          height: 12px;
          display: block;
          margin: 0 5px;
          border-radius: 50%;
          //border: 1px solid $grey;
          background: $grey;
          -webkit-transition: background 0.2s ease-in;
          -moz-transition: background 0.2s ease-in;
          -ms-transition: background 0.2s ease-in;
          -o-transition: background 0.2s ease-in;
          transition: background 0.2s ease-in;
          &.owl-dot {
            background: $grey;
          }
          &.active {
            background: $magenta-light;
          }
    }
    & > div {
      width: 10px;
      height: 10px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
      border-radius: 50%;
      &.active {
      }
    }
  }
}
