/* -----------------------------------
HEADER
----------------------------------- */
body {
    overflow: inherit;
    &.overflow{
        overflow: hidden;
    }
}




#header {
    .header-top {
        @include clearfix;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: $white;
        z-index: 1001;
        padding-top: 10px;
        padding-bottom: 10px;
        @include transition(padding $speed ease-in);
        height: 50px;
        border-bottom: 1px solid $grey;
        .container {
            height: 100%;
            .row {
                position: static;
                height: 100%;
                @include flexbox;
                align-items: center;
            }
        }
        &.is-scrolled{
            @include min(md) {
                .container{
                    .row{
                        .burger-block, .search-open{
                            @include transition(all $speed ease-in);
                            opacity: 0;
                        }
                        .header-logo{
                            img{
                                height: 15px;
                            }
                        }
                    }
                }
            }
        }

    }
}







.header-logo {
    //padding-left: 5px;
    //padding-right: 0px;
    @include flex(0,1, 50%);
    @include min(sm) {
        //padding-left: 0px;
        @include flex(0,1, 33.33%);
    }
    a {
        &:hover{
            img{
                opacity: 0.5;
            }
        }
        img{
            @include transition(all $speed ease-in);
            height: 15px;
            margin-top: 6px;
            @include min(md) {
                height: 15px;
            }
        }
    }
}

.burger-block{
    @include flex(0,1, 50%);
    @include transition(opacity $speed ease-in);
    cursor: pointer;
    &:hover{
        opacity: .5;
    }
    @include min(sm) {
        @include flex(0,1, 33.33%);
        display: flex;
        p {
            @include flex(0,1, 100%);
        }
    }
}

.search-open {
    display: none;
    margin-left: auto;
    @include transition(opacity $speed ease-in);
    //padding: 0;
    @include min(sm) {
        display: block;
        @include flex(0,1, 33.33%);
    }
    &:hover {
        opacity: .5;
    }
    img {
        width: 22px;
        padding-top: 6px;
        @include transition(width $speed ease-in);
        float: right;
    }
}

.offcanvas-menu {
    &.is-open {
        /* MENU IS OPEN */
        .header-logo {
            @include padding;
            @include min(lg) {
                margin-left: 5px;
            }
        }
    }
}



