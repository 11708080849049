.mod_latest_case, .mod_all_case {
	@include min(md) {
		@include padding;
	}
	@include min(lg) {
		margin-right: 5px;
		margin-left: 5px;
	}
	.layout_latest_cases {
		display: grid;
		margin-top: 40px;
		margin-bottom: 40px;
		@include padding;


		@include min(md) {
			box-shadow: 0px 2px 13px rgba(0,0,0,.2);
			//box-shadow: 0px 13px 56px rgba(0,0,0,.2);
			display: flex;
			padding: 0;
			&.odd{
				.text-holder {
					order: 1;
					border-bottom: 2px solid $grey;
					border-right: 2px solid $grey;
					border-left: 0px;
				}
				.image-holder {
					order: 0;

				}
			}
		}

		> div {
			@include flex(0,0, 100%);
			@include min(md) {
				@include flex(0,1, 50%);
			}
		}

		.text-holder {
			padding: 0;
			border: none;
			order: 1;

			@include min(md) {
				order: 0;
				padding: 77px 94px;
				border-top: 2px solid $grey;
				border-bottom: 2px solid $grey;
				border-left: 2px solid $grey;
				border-right: 0px;
			}

			.subheadline{
				margin-bottom: 30px;
				margin-top: 30px;
				@include min(md) {
					margin-bottom: 30px;
					margin-top: 0px;
				}
			}

			h3{
				margin-top: 0px;
				@include min(md) {
					@include font-size(3.0);
					letter-spacing: 1.2px;
					line-height: 1.4;
				}
				a{
					color: $black;
					&:hover{
						color: $hover;
					}
				}
			}

			.more{
				text-transform: uppercase;
				&:before {
				    content: '';
				    display: block;
				    height: 2px;
				    background: $grey;
				    position: relative;
				    top: 30px;
	                @include transition(width $speed-slider ease-in);
				}
		        &:after {
		            content: '';
		            display: block;
		            width: 0;
		            height: 2px;
		            background: $magenta-light;
		            position: relative;
		            top: 4px;
		            left: 0;
		            @include transition(width $speed ease-in);
		        }
		        &:hover {
		            &:after {
		                @include transition(width $speed ease-in);
		                width: 100%;
		            }
		            & > img{
		                //@include transition(opacity $speed ease-in);
		            	//opacity: .3;
		            }
		            & > .hoverMagenta{
		        		display: none;
		            }
		            & > .hoverBlue{
		        		display: block;
		            }
		        }
		        img{
		        	position: relative;
	    			float: right;
		        }
		        .hoverBlue{
		        	display: none;
		        }
			}
		}




		.image-holder {
			position: relative;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			//min-height: 500px;
			margin: 0px 0px;
			min-height: 360px;
			order: 0;

			@include min(md) {
				min-height: auto;
				order: 1;
			}

			.image_container {
				height: 100%;
				@include max(991px) {
					height: 250px;
				}
				img {
					opacity: 0;
					visibility: hidden;
				}
			}
		}

		/*
		.teaser-info {
			@include clearfix;
			.title {
			    padding: 15px 30px 15px 30px;
			    line-height: 1;
			    color: $white;
			    background: $black;
			    //font-variation-settings: $bold;
			    @include font-size(2.6);
			    margin-bottom: 0;
			    float: left;
			}
			.info {
			    position: relative;
			    top: 70px;
			    font-style: italic;
			    @include font-size(2.2);
			    float: left;
			    clear: left;
			}
		}
		*/
	}
}
