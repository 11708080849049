/* -----------------------------------
COLORS
----------------------------------- */

$text-color: rgba(153, 153, 153, 1);
$headline-color: rgba(0, 0, 0, 1);


$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);
$brown: rgba(60, 15, 25, 1);
$grey: rgba(219, 219, 219, 1);
$grey-placeholder: rgba(150, 150, 150, 1);

$orange: rgba(255, 80, 0, 1);
$magenta-light: rgba(255, 0, 69, 1);
$magenta-contact: rgba(151, 0, 44, 1);
$magenta-dark: rgba(83, 29, 29, 1);
$magenta-gradient-dark: rgba(173, 0, 58, 1);


$magenta-gradient: linear-gradient(45deg, $magenta-dark, $magenta-light);

$magenta-gradient-light-dark: linear-gradient(180deg, $magenta-light, $magenta-gradient-dark);
$magenta-gradient-dark-light: linear-gradient(180deg, $magenta-gradient-dark, $magenta-light);

$menu-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

//$link: rgba(5, 38, 77, 1);
$link: rgba(255, 1, 69, 1);

$link-white:  rgba(255, 255, 255, 1);
$link-black: rgba(0, 0, 0, 1);

$hover-blue: rgba(52, 137, 235, 1);
$hover: rgba(255, 0, 69, 1);
$hover-black: rgba(0, 0, 0, 1);
$hover-white: rgba(255, 255, 255, 1);


$error-color: #ff0000;




/* SPACE */
@use "sass:math";
$gutter: 10px;
$gutter-sm: 10px;
$gutter-lg: 15px;

// $gap: 35px / 2;
// $gap-sm: 50px / 2;
// $gap-lg: 80px / 2;
$gap: math.div(35px, 2);
$gap-sm: math.div(50px, 2);
$gap-lg: math.div(80px, 2);

$width-sm: 716px;
$width-md: 974px;
$width-lg: 1200px;

$speed: 0.2s;
$speed-slider: 2s;
$radius: 30px;

$header-height: 175px;
$header-height-sm: 175px;
$header-height-md: 175px;
$header-height-lg: 112px;

$header-top-height: 60px;
$header-top-height-sm: 60px;
$header-top-height-md: 60px;
$header-top-height-lg: 112px;

$header-bottom-height: 100px;
$header-bottom-height-sm: 100px;
$header-bottom-height-md: 100px;
$header-bottom-height-lg: 130px;

@mixin gap-top {
    margin-top: $gap;
    @include min(sm) {
        margin-top: $gap-sm;
    }
    @include min(lg) {
        margin-top: $gap-lg;
    }
}
@mixin gap {
    margin-top: $gap;
    margin-bottom: $gap;
    @include min(sm) {
        margin-top: $gap-sm;
        margin-bottom: $gap-sm;
    }
    @include min(lg) {
        margin-top: $gap-lg;
        margin-bottom: $gap-lg;
    }
}
@mixin gap-bottom {
    margin-bottom: $gap;
    @include min(sm) {
        margin-bottom: $gap-sm;
    }
    @include min(lg) {
        margin-bottom: $gap-lg;
    }
}

@mixin padding {
    padding-left: $gutter;
    padding-right: $gutter;
    @include min(sm) {
        padding-left: $gutter-sm;
        padding-right: $gutter-sm;
    }
    @include min(lg) {
        padding-left: $gutter-lg;
        padding-right: $gutter-lg;
    }
}
@mixin padding-top {
    padding-top: $gap;
    @include min(sm) {
        padding-top: $gap-sm;
    }
    @include min(lg) {
        padding-top: $gap-lg;
    }
}
@mixin padding-bottom {
    padding-bottom: $gap;
    @include min(sm) {
        padding-bottom: $gap-sm;
    }
    @include min(lg) {
        padding-bottom: $gap-lg;
    }
}
