#news {
	overflow: visible;
}
.news-slider {
	width: 100%;
	&.block {
		overflow: visible;
	}
	.owl-carousel{
		.layout_latest{
			margin-bottom: 0px;
		}
		.owl-nav {
			display: none;
			@include min(lg) {
				display: block;
				top: calc(50% - 60px);
				width: 100vw;
				height: 120px;
				left: 50%;
				@include transformX(-50%);
				z-index: -1;
			}
			button {
				&.owl-prev, &.owl-next {
					position: absolute;
					top: calc(50% - 40px);
					width: 30px;
					height: 80px;
					border: none;
					font-size: 0;
					z-index: 999;
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
					background-position: 50% 50%;
					background-repeat: no-repeat;
					background-size: cover;
					@include transition(background-image $speed ease-in);
					@include min(lg) {
						top: calc(50% - 40px);
						width: 30px;
						height: 80px;
					}
					@include min(xl) {
						top: calc(50% - 60px);
						width: 120px;
						height: 120px;
					}
					&:hover{
						background-color: unset;
					}
				}
			}
			.owl-prev {
				// background-image: url("../../images/icons/chevron-left-slider-cases.svg");
				background-image: url("../../images/icons/chevron-left-slider-cases-magenta.svg");
				@include min(lg) {
					left: 24px;
				}
				@include min(xl) {
					left: 24px;
				}
				&:hover{
					opacity: 0.3;
				}
			}
			
			.owl-next {
				left: auto;
				// background-image: url("../../images/icons/chevron-right-slider-cases.svg");
				background-image: url("../../images/icons/chevron-right-slider-cases-magenta.svg");
				@include min(lg) {
					right: 24px;
				}
				@include min(xl) {
					right: 24px;
				}
				&:hover{
					opacity: 0.3;
				}
			}
		}
	}
	.layout_latest {
		display: grid;
		margin-bottom: 40px;
		@include min(lg) {
			display: flex;
		}
		@include min(xl) {
			// display: flex;
		}
		> div {
			@include flex(0,0, 100%);
			@include min(md) {
				@include flex(0,1, 50%);
			}
		}
		.image-holder {
			position: relative;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			//background-size: contain;
			min-height: 360px;
			order: -1;
			margin: 0px 0px;

			@include min(xl) {
				min-height: auto;
				order: auto;
			}
			.image_container {
				height: 100%;
				@include max(991px) {
					height: 250px;
				}
				img {
					opacity: 0;
					visibility: hidden;
				}
			}
			.video_container {
					@include min(lg) {
						position: absolute;
						height: 100%;
						width: 100%;
						overflow: hidden;

						display: flex;
					}
				video {
					@include min(md) {
						position: absolute;
						left: 50%;
						top: 50%;
						-webkit-transform: translate(-50%, -50%);
						-moz-transform: translate(-50%, -50%);
						-ms-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
						// z-index: -1;
						cursor: pointer;
						z-index: 9;
					}
					@include min(lg) {
						min-width: 200%;
						max-width: none;
						min-height: 200%;

						min-width: 110%;
						min-height: 110%;
						max-width: 125%;
					}
					@include min(max) {
						max-width: 100%;
					}
					&.background-video {
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100%;
						height: 100%;
						object-fit: cover;
						transform: translate(-50%, -50%);
						filter: blur(10px);
						z-index: 0;
					}
					&.main-video {
						all: unset;
						position: relative;
						margin: auto;
						z-index: 1;
						width: 100%;
						height: auto;
						cursor: pointer;
						z-index: 9;
						@include min(xxl) {
							all: inherit;
						}
					}
				}
			}
		}
		&.vid-inGrid {
			.image-holder {
				.video_container {
					@include min(xl) {
						position: relative;
					}
					video {
						width: 100%;
						@include min(xl) {
							position: relative;
							top: 0;
							left: 0;
							min-width: inherit;
							min-height: inherit;
							transform: none;
						}
						&.background-video {
							position: absolute;
							top: 50%;
							left: 50%;
							width: 100%;
							height: 100%;
							object-fit: cover;
							transform: translate(-50%, -50%);
							filter: blur(10px);
							z-index: 0;
						}
						&.main-video {
							all: unset;
							position: relative;
							z-index: 1;
							width: 100%;
							height: auto;
						}
					}
				}
			}
		}
		.text-holder {
			padding: 52px 50px 52px 60px;
			border: 2px solid $grey;
			background-color: $white;
			@include min(md) {
				padding: 52px 93px;
				//border-top: 2px solid $grey;
				//border-bottom: 2px solid $grey;
				//border-left: 2px solid $grey;
			    //display: flex;
			    flex-flow: column nowrap;
			}
			@include min(xl) {
			}
			.info{
				display: none;
				margin-bottom: 40px;
			}
			h4{
				margin-top: 0px;
			}
			.ce_text{
				margin-bottom: 16px;
			}
			.button-block{
				margin-bottom: 0px;
				margin-left: 0;
				margin-top: auto;
				a{
					margin-right: 20px;
					margin-bottom: 0px;
				}
				a.button:nth-child(2) {
				    //float: right;
				}
			}

			.more{
				margin-top: auto;
				text-transform: uppercase;
				&:before {
				    content: '';
				    display: block;
				    height: 2px;
				    background: $grey;
				    position: relative;
				    top: 30px;
	                @include transition(width $speed-slider ease-in);
				}
		        &:after {
		            content: '';
		            display: block;
		            width: 0;
		            height: 2px;
		            background: $magenta-light;
		            position: relative;
		            top: 4px;
		            left: 0;
		            @include transition(width $speed ease-in);
		        }
		        &:hover {
		            &:after {
		                @include transition(width $speed ease-in);
		                width: 100%;
		            }
		            & > img{
		                //@include transition(opacity $speed ease-in);
		            	//opacity: .3;
		            }
		            & > .hoverMagenta{
		        		display: none;
		            }
		            & > .hoverBlue{
		        		display: block;
		            }
		        }
		        img{
		        	position: relative;
	    			float: right;
	    			width: auto;
		        }
		        .hoverBlue{
		        	display: none;
		        }
			}
		}
	}
}






/* SLIDER FULL LIST */
.mod_all_slider {
	width: 100%;
	@include min(lg) {
		padding-left: 10px;
		padding-right: 10px;
	}

	.layout_latest_slider {
		display: grid;
		margin-top: 40px;
		margin-bottom: 40px;
		@include padding;
		display: flex;
		flex-direction: column;

		@include min(lg) {
			//box-shadow: 0px 2px 13px rgba(0,0,0,.2);
			border: 2px solid $grey;
			display: flex;
			flex-direction: inherit;
			@include flex(0,0, 100%);
			padding: 0;
			&.odd{
				.text-holder {
					order: 1;
					/*
					border-bottom: 2px solid $grey;
					border-right: 2px solid $grey;
					border-left: 0px;
					*/
				}
				.image-holder {
					order: 0;
					//optimze Pics
					//background-position: 0% 50%;
				}
			}
			//optimze Pics
			&.even{
				.image-holder {
					//optimze Pics
					//background-position: 100% 50%;
				}
			}
		}

		> div {
			@include flex(0,0, 100%);
		}
		/* pics always 66%, text 33% */
		.text-holder {
			@include min(md) {
				@include flex(0,1, calc(50% + 2px));
			}
			@include min(max) {
				//@include flex(0,1, calc(33.333% + 2px));
			}
		}
		.image-holder {
			@include min(md) {
				@include flex(0,1, 50%);
			}
			@include min(max) {
				//@include flex(0,1, 66.667%);
			}
			.video_container {
					@include min(lg) {
						position: absolute;
						height: 100%;
						width: 100%;
						overflow: hidden;
					}
				video {
					@include min(lg) {
						position: absolute;
						min-width: 200%;
						left: 50%;
						top: 50%;
						min-height: 200%;
						-webkit-transform: translate(-50%, -50%);
						-moz-transform: translate(-50%, -50%);
						-ms-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
						z-index: -1;
					}
					@include min(lg) {
						min-width: 145%;
						min-height: 145%;
					}
					@include min(xl) {
						min-width: 110%;
						min-height: 110%;
					}
				}
			}
		}
		&.vid-inGrid {
			.image-holder {
				.video_container {
						@include min(lg) {
							position: relative;
						}
					video {
						width: 100%;
						@include min(lg) {
							position: relative;
							top: 0;
							left: 0;
							min-width: inherit;
							min-height: inherit;
							transform: none;
						}
					}
				}
			}
		}

		.text-holder {
			padding: 0;
			border: none;
			order: 1;

			border-bottom: 2px solid $grey;
			border-left: 2px solid $grey;
			border-right: 2px solid $grey;
			padding: 20px 20px;
			@include min(sm) {
				padding: 60px 60px;
			}

			@include min(lg) {
				order: 0;
				border: none;
				padding: 60px 60px;
				/*
				border-top: 2px solid $grey;
				border-bottom: 2px solid $grey;
				border-left: 2px solid $grey;
				border-right: 0px;
				*/
			}

			.info{
				margin-bottom: 10px;
				margin-top: 30px;
				@include min(lg) {
					margin-bottom: 26px;
					margin-top: 0px;
				}
			}

			.subheadline{
				margin-bottom: 0px;
				margin-top: 0px;
				color: $black;
			}

			h3{
				margin-top: 0px;
				margin-bottom: 10px;
				@include min(md) {
					@include font-size(3.0);
					letter-spacing: 1.2px;
					//line-height: 1.4;
					line-height: 1;
				}
				a{
					color: $black;
					&:hover{
						color: $hover;
					}
				}
			}

			.more{
				text-transform: uppercase;
				&:before {
				    content: '';
				    display: block;
				    height: 2px;
				    background: $grey;
				    position: relative;
				    top: 30px;
	                @include transition(width $speed-slider ease-in);
				}
		        &:after {
		            content: '';
		            display: block;
		            width: 0;
		            height: 2px;
		            background: $magenta-light;
		            position: relative;
		            top: 4px;
		            left: 0;
		            @include transition(width $speed ease-in);
		        }
		        &:hover {
		            &:after {
		                @include transition(width $speed ease-in);
		                width: 100%;
		            }
		            & > img{
		                //@include transition(opacity $speed ease-in);
		            	//opacity: .3;
		            }
		            & > .hoverMagenta{
		        		display: none;
		            }
		            & > .hoverBlue{
		        		display: block;
		            }
		        }
		        img{
		        	position: relative;
	    			float: right;
	    			width: auto;
		        }
		        .hoverBlue{
		        	display: none;
		        }
			}
		}




		.image-holder {
			position: relative;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			margin: 0px 0px;
			order: 0;

			border-top: 2px solid $grey;
			border-left: 2px solid $grey;
			border-right: 2px solid $grey;

			min-height: 360px;

			@include min(lg) {
				// padding: 60px 60px;
				min-height: 360px;
				order: 1;
				border: none;
			}

			img {
				@include min(lg) {
					display: none;
				}
			}



		}
	}
}

